import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Tooltip } from '@material-ui/core/';
import SecurityIcon from '@material-ui/icons/Security';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { ASSISTANT_STEPS } from '../../helpers/Constants';

const useStyles = makeStyles({
  assistantTooltip: {
    backgroundColor: '#7300FF',
    textAlign: 'left',
    font: 'bold 12px/15px Muli',
    color: '#FFFFFF',
    opacity: 1,
    maxWidth: '500px',
    paddingTop: '10px',
    borderRadius: '8px',
    marginLeft: props => {
      return props.type === 'selectTabs' ? -100 : 0;
    },
  },
  assistantTooltipArrow: {
    color: '#7300FF',
  },
  assistantTooltipIcon: {
    marginTop: '7px',
  },
  tooltipText: {
    textAlign: 'center',
  },
  assistantTooltipAnimation: {
    backgroundColor: '#7300FF',
    textAlign: 'left',
    font: 'bold 12px/15px Muli',
    color: '#FFFFFF',
    opacity: 1,
    maxWidth: '500px',
    paddingTop: '10px',
    borderRadius: '8px',
    transform: 'translate(-50%,-50%)',
    overflow: 'hidden',
    animation: '$animate 2.5s linear infinite',
  },

  '@keyframes animate': {
    '0%': { transform: 'translateY(0)' },
    '20%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(0)' },
    '80%': { transform: 'translateY(0)' },
    '100%': { transform: 'translateY(0)' },
    '40%': { transform: 'translateY(-30px)' },
    '60%': { transform: 'translateY(-15px)' },
  },
});

const getConfig = (assistantType, type, classes, assistantActiveStep) => {
  const {
    STEP_OPERATION_SII,
    STEP_OPERATION_TYPE,
    STEP_OPERATION_SELECT_INVOICES,
    STEP_OPERATION_SELECT_DATES,
    STEP_PROFILE_SII,
    STEP_PROFILE_LEGAL,
    STEP_PROFILE_BANK_ACCOUNT,
    STEP_PROFILE_PREVIRED,
  } = ASSISTANT_STEPS;
  const config = {
    operation: {
      sii: {
        title: [
          'Ingresa tu Rut empresa y claves del SII.',
          ' Recuerda! Tus claves están encriptadas.',
        ],
        icon: <SecurityIcon className={classes.assistantTooltipIcon} />,
        active: assistantActiveStep === STEP_OPERATION_SII,
        placement: 'right',
      },
      selectTabs: {
        title: ['Selecciona el tipo de financiamento.'],
        icon: <MonetizationOnIcon />,
        active: assistantActiveStep === STEP_OPERATION_TYPE,
        placement: 'right',
      },
      selectInvoice: {
        title: [' Selecciona una o más facturas a financiar.'],
        icon: <ReceiptIcon />,
        active: assistantActiveStep === STEP_OPERATION_SELECT_INVOICES,
        placement: 'right',
      },
      toTableDate: {
        title: ['Avanza cuando estes listo.'],
        icon: '',
        active: assistantActiveStep === STEP_OPERATION_SELECT_INVOICES,
        placement: 'bottom',
      },
      selectDate: {
        title: [
          ' Te sugerimos fechas de pago,',
          'dado el historial de tu pagador.',
          'Puedes modificarlas si así lo deseas.',
        ],
        icon: <CalendarTodayIcon />,
        active: assistantActiveStep === STEP_OPERATION_SELECT_DATES,
        placement: 'left',
      },
    },
    profile: {
      pfx: {
        title: ['Adjunta archivo.'],
        icon: '',
        active: assistantActiveStep === STEP_PROFILE_SII,
        placement: 'bottom',
      },
      legal: {
        title: ['Ingresa tus representantes', 'legales.'],
        icon: '',
        active: assistantActiveStep === STEP_PROFILE_LEGAL,
        placement: 'bottom',
      },
      bankAccount: {
        title: ['Ingresa tu cuenta.'],
        icon: '',
        active: assistantActiveStep === STEP_PROFILE_BANK_ACCOUNT,
        placement: 'bottom',
      },
      previred: {
        title: ['Ingresa tu contraseña', 'PREVIRED.'],
        icon: '',
        active: assistantActiveStep === STEP_PROFILE_PREVIRED,
        placement: 'bottom',
      },
      pendingTask: {
        title: [
          'Puedes completar este paso',
          'ingresando más tarde a',
          'Tareas pendientes',
        ],
        icon: '',
        active: true,
      },
    },
  };
  return config[assistantType][type];
};
const AssistantToolTip = props => {
  const classes = useStyles(props);
  const { type, open, element } = props;
  const showAssistantStepper = useSelector(state => state.assistant.active);
  const assistantActiveStep = useSelector(state => state.assistant.activeStep);
  const assistantType = useSelector(state => state.assistant.type);

  const { title = [], icon = '', active = false, placement = '' } =
    getConfig(assistantType, type, classes, assistantActiveStep) || {};
  const show = showAssistantStepper && open && active;

  return (
    <Tooltip
      open={show}
      arrow
      placement={placement}
      classes={{
        tooltip:
          type === 'pendingTask'
            ? classes.assistantTooltipAnimation
            : classes.assistantTooltip,
        arrow: classes.assistantTooltipArrow,
      }}
      title={
        <Grid container direction="row" justify="center" spacing={1}>
          <Grid item>{icon}</Grid>
          <Grid item>
            <div>
              {title.map(text => (
                <Typography
                  key={text}
                  className={classes.tooltipText}
                  variant="subtitle2"
                >
                  {text}
                </Typography>
              ))}
            </div>
          </Grid>
        </Grid>
      }
    >
      {element}
    </Tooltip>
  );
};

AssistantToolTip.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  element: PropTypes.node.isRequired,
};

export default AssistantToolTip;
