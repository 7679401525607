import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BankAccountCard from './elements/BankAccountCard';
import UserBankAccountDialog from './elements/UserBankAccountDialog';
import OrangeButton from './elements/OrangeButton';
import CustomPanel from './elements/CustomPanel';
import { fetchBanks } from '../actions/bankAction';
import {
  registerBankAccount,
  deleteBankAccount,
  setDefaultBankAccount,
} from '../actions/businessAction';
import ConfirmDialog from './elements/ConfirmDialog';
import { BUTTON_LABEL, ASSISTANT_STEPS } from '../helpers/Constants';
import AssistantTooltip from './elements/AssistantTooltip';
import { setActiveStep, setPendingTasks } from '../actions/assistantAction';

const useStyles = {
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#E7E5EC',
    margin: '16px 0',
  },
  container: {
    display: 'grid',
    gridGap: '32px',
  },
  title: {
    font: 'bold 20px Muli',
    color: '#575360',
  },
  subtitle: {
    font: 'normal 14px Muli',
    color: '#575360',
  },
  cardTitle: {
    font: 'normal 16px Muli',
    color: '#75B801',
  },
  cardSubtitle: {
    color: '#A8A4B3',
  },
  userData: {
    fontWeight: 'bold',
    color: '#6C6776',
  },
  button: {
    background: 'white',
    border: '2px solid #F87348',
    borderRadius: '30px',
    color: ' #F87348',
    fontFamily: 'Muli',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: ' #F87348',
      color: ' white',
    },
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#A8A4B3',
    fontFamily: 'Muli',
  },
  tooltip: {
    color: '#F87348',
  },
  chipSuccess: {
    width: '100%',
    fontWeight: 'bold',
    color: 'white',
    background: '#75B801',
  },
  checkIcon: {
    color: 'white',
  },
  paper: {
    padding: 20,
    borderRadius: '20px',
  },
  icon: {
    color: '#75B801',
    fontSize: '25px',
  },
  flexColumns: {
    display: 'flex',
    direction: 'column',
  },
  addButton: {
    fontWeight: 'normal',
    padding: 0,
    textTransform: 'capitalize',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#75B801',
    fontFamily: 'Muli',
    '&:hover': {
      background: 'transparent',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
};

const { STEP_PROFILE_PREVIRED } = ASSISTANT_STEPS;

class UserBankAccounts extends Component {
  constructor(props) {
    super(props);
    const { showAssistantStepper } = this.props;

    this.state = {
      showNewBankAccountDialog: false,
      showDefaultAccountDialog: false,
      showDeleteBankAccountDialog: false,
      showSetDefaultBankAccount: false,
      showAssistantTooltip: showAssistantStepper,
    };
  }

  componentDidMount = () => {
    this.props.fetchBanks();
  };

  componentDidUpdate = prevProps => {
    const {
      showDefaultAccountDialog,
      showDeleteBankAccountDialog,
      showNewBankAccountDialog,
    } = this.state;
    const {
      settingDefaultBankAccount,
      deletingBankAccount,
      bankAccountRegistered,
      bankAccountError,
    } = this.props;

    if (showDefaultAccountDialog && settingDefaultBankAccount) {
      this.setState(prevState => {
        return { ...prevState, showDefaultAccountDialog: false };
      });
    }
    if (showDeleteBankAccountDialog && deletingBankAccount) {
      this.setState(prevState => {
        return { ...prevState, showDeleteBankAccountDialog: false };
      });
    }

    if (
      showNewBankAccountDialog &&
      !prevProps.bankAccountRegistered &&
      bankAccountRegistered &&
      !bankAccountError
    ) {
      this.setState(prevState => {
        return { ...prevState, showNewBankAccountDialog: false };
      });
    }
  };

  getBankAccounts = () => {
    const {
      auth: { user },
    } = this.props;
    const { Businesses } = user || {};
    const [business] = Businesses || [];
    const { BankAccounts = [] } = business;

    return BankAccounts.map(account => {
      return {
        bankName: account.Bank.name,
        ...account,
      };
    });
  };

  handleShowNewBankAccountDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      showNewBankAccountDialog: true,
      showAssistantTooltip: false,
    }));
    this.props.resetBankAccountDialog();
  };

  handleCloseNewBankAccountDialog = () => {
    const {
      resetBankAccountDialog,
      showAssistantStepper,
      setActiveStep,
      setPendingTasks,
    } = this.props;
    this.setState(prevState => ({
      ...prevState,
      showNewBankAccountDialog: false,
    }));
    resetBankAccountDialog();
    if (showAssistantStepper) {
      setActiveStep(STEP_PROFILE_PREVIRED);
      setPendingTasks(true);
    }
  };

  handleSubmitBankAccount = bankAccountData => {
    const {
      auth: { user },
      registerBankAccount,
      showAssistantStepper,
      setActiveStep,
    } = this.props;
    const { id: userId, Businesses = [] } = user;
    const [{ id: businessId }] = Businesses;
    const bankAccountDataObj = { ...bankAccountData };
    bankAccountDataObj.userId = userId;
    // The bank account table now uses sourceId instead of businessId
    bankAccountDataObj.sourceId = businessId;
    // This new property is to indicate whether the new bank acccount is for a business or a fund (more sources could be implented in the future).
    bankAccountDataObj.source = 'BUSINESS';
    registerBankAccount(bankAccountDataObj, businessId, userId);
    if (showAssistantStepper) setActiveStep(STEP_PROFILE_PREVIRED);
  };

  handleShowDeleteBankAccountDialog = bankAccount => {
    this.props.selectBankAccount(bankAccount);
    this.setState(prevState => ({
      ...prevState,
      showDeleteBankAccountDialog: true,
    }));
  };

  handleCloseDeleteBankAccountDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      showDeleteBankAccountDialog: false,
    }));
    this.props.resetDeleteBankAccountDialog();
  };

  handleDeleteBankAccount = bankAccount => {
    this.props.selectBankAccount(bankAccount);
    const {
      selectedBankAccount: { id: bankAccountId },
      deleteBankAccount,
    } = this.props;
    deleteBankAccount(bankAccountId);
  };

  handleShowDefaultAccountDialog = bankAccount => {
    this.props.selectBankAccount(bankAccount);
    this.setState(prevState => ({
      ...prevState,
      showDefaultAccountDialog: true,
    }));
  };

  handleCloseDefaultAccountDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      showDefaultAccountDialog: false,
    }));
    this.props.resetDefaultBankAccountDialog();
  };

  handleSetDefaultAccount = () => {
    const {
      selectedBankAccount: { id: bankAccountId },
      setDefaultBankAccount,
    } = this.props;
    setDefaultBankAccount(bankAccountId);
  };

  render() {
    const { classes, banks, auth } = this.props;
    const {
      showNewBankAccountDialog,
      showDeleteBankAccountDialog,
      showDefaultAccountDialog,
      showAssistantTooltip,
    } = this.state;
    const { ELIMINAR, CAMBIAR } = BUTTON_LABEL;

    const bankAccounts = this.getBankAccounts();
    const accountsSize = bankAccounts.length;
    return (
      <Fade in timeout={400}>
        <Box className={classes.container}>
          {banks && (
            <UserBankAccountDialog
              open={showNewBankAccountDialog}
              handleCloseDialog={this.handleCloseNewBankAccountDialog}
              handleSubmit={bankAccountData =>
                this.handleSubmitBankAccount(bankAccountData)
              }
              banks={banks}
              user={auth.user}
            />
          )}
          <ConfirmDialog
            isOpen={showDeleteBankAccountDialog}
            handleClose={this.handleCloseDeleteBankAccountDialog}
            title="Eliminar cuenta"
            message="¿Estás seguro que deseas eliminar la cuenta?"
            buttonLabel={ELIMINAR}
            buttonOnAccept={bankAccount =>
              this.handleDeleteBankAccount(bankAccount)
            }
          />
          <ConfirmDialog
            isOpen={showDefaultAccountDialog}
            handleClose={this.handleCloseDefaultAccountDialog}
            title="Cambiar cuenta predeterminada"
            message="Si cambias tu cuenta predeterminada ahora, será la que usaremos para transferirte tus futuras operaciones. Necesitamos que confirmes este cambio."
            buttonLabel={CAMBIAR}
            buttonOnAccept={bankAccount =>
              this.handleSetDefaultAccount(bankAccount)
            }
          />
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              Cuentas Bancarias{' '}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.subtitle}>
              Necesitamos esta información para poder realizar la transferencia.
              Agrega una o varias cuentas y elige la predeterminada para tus
              operaciones, cuando quieras podrás modificarlo desde esta sección.
            </Typography>
          </Grid>

          <CustomPanel
            variant="green-title"
            title="Mis cuentas"
            buttons={
              <AssistantTooltip
                type="bankAccount"
                open={showAssistantTooltip}
                element={
                  <Grid item xs={8} align="center">
                    <OrangeButton
                      onClick={this.handleShowNewBankAccountDialog}
                      text="Agregar cuenta nueva"
                    />
                  </Grid>
                }
              />
            }
          >
            {bankAccounts.map((bankAccount, index) => (
              <>
                <BankAccountCard
                  // eslint-disable-next-line react/no-array-index-key
                  key={`separator-${index}`}
                  bankAccount={bankAccount}
                  className={classes.paper}
                  handleShowDeleteDialog={
                    this.handleShowDeleteBankAccountDialog
                  }
                  handleShowDefaultAccountDialog={
                    this.handleShowDefaultAccountDialog
                  }
                />
                {accountsSize !== index + 1 && (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`separator-${index}`}
                    className={classes.separator}
                  />
                )}
              </>
            ))}
          </CustomPanel>
        </Box>
      </Fade>
    );
  }
}

UserBankAccounts.defaultProps = {
  settingDefaultBankAccount: false,
  selectedBankAccount: null,
};

UserBankAccounts.propTypes = {
  fetchBanks: PropTypes.func.isRequired,
  auth: PropTypes.objectOf(Object).isRequired,
  registerBankAccount: PropTypes.func.isRequired,
  resetBankAccountDialog: PropTypes.func.isRequired,
  selectBankAccount: PropTypes.func.isRequired,
  resetDeleteBankAccountDialog: PropTypes.func.isRequired,
  selectedBankAccount: PropTypes.objectOf(Object),
  deleteBankAccount: PropTypes.func.isRequired,
  resetDefaultBankAccountDialog: PropTypes.func.isRequired,
  setDefaultBankAccount: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(Object).isRequired,
  banks: PropTypes.arrayOf(Object).isRequired,
  settingDefaultBankAccount: PropTypes.bool,
  deletingBankAccount: PropTypes.bool.isRequired,
  bankAccountError: PropTypes.bool.isRequired,
  bankAccountRegistered: PropTypes.bool.isRequired,
  showAssistantStepper: PropTypes.bool.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setPendingTasks: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    banks: state.banks.banks,
    selectedBankAccount: state.business.selectedBankAccount,
    settingDefaultBankAccount: state.business.settingDefaultBankAccount,
    deletingBankAccount: state.business.deletingBankAccount,
    bankAccountRegistered: state.business.bankAccountRegistered,
    bankAccountError: state.business.bankAccountError,
    showAssistantStepper: state.assistant.active,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBanks: () => dispatch(fetchBanks()),
    registerBankAccount: (bankAccountData, businessId, userId) =>
      dispatch(registerBankAccount(bankAccountData, businessId, userId)),
    resetBankAccountDialog: () =>
      dispatch({ type: 'RESET_BANK_ACCOUNT_DIALOG' }),
    deleteBankAccount: bankAccountData =>
      dispatch(deleteBankAccount(bankAccountData)),
    resetDeleteBankAccountDialog: () =>
      dispatch({ type: 'RESET_BANK_ACCOUNT_DELETE_DIALOG' }),
    resetDefaultBankAccountDialog: () =>
      dispatch({ type: 'RESET_DEFAULT_BANK_ACCOUNT_DIALOG' }),
    selectBankAccount: bankAccount =>
      dispatch({ type: 'SELECT_BANK_ACCOUNT', payload: { bankAccount } }),
    setDefaultBankAccount: bankAccountId =>
      dispatch(setDefaultBankAccount(bankAccountId)),
    setActiveStep: step => {
      dispatch(setActiveStep(step));
    },
    setPendingTasks: active => {
      dispatch(setPendingTasks(active));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(UserBankAccounts));
