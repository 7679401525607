import axios from 'axios';
import {
  FETCH_STAKEHOLDERS_START,
  FETCH_STAKEHOLDERS_FAILURE,
  FETCH_STAKEHOLDERS_SUCCESS,
  CREATE_STAKEHOLDERS_START,
  CREATE_STAKEHOLDERS_FAILURE,
  CREATE_STAKEHOLDERS_SUCCESS,
  EDIT_STAKEHOLDERS_START,
  EDIT_STAKEHOLDERS_FAILURE,
  EDIT_STAKEHOLDERS_SUCCESS,
  DELETE_STAKEHOLDERS_START,
  DELETE_STAKEHOLDERS_FAILURE,
  DELETE_STAKEHOLDERS_SUCCESS,
  RESET_STAKEHOLDERS_ACTIONS,
  DOWNLOADING_LEGAL_FILE_START,
  DOWNLOADING_LEGAL_FILE_END,
} from './types';
import { addNotification } from './notificationsAction';
import { fetchSettingsResume } from './businessAction';
import { b64toBlob, download } from '../helpers/fileHandling';
import {
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  TERMS_LABEL,
  POLICY,
  POLICY_LABEL,
} from '../helpers/Constants';
import {
  FETCH_STAKEHOLDER_MSG_ERROR,
  CREATE_STAKEHOLDER_MSG_SUCCESS,
  EDIT_STAKEHOLDER_MSG_SUCCESS,
  DELETE_STAKEHOLDER_MSG_SUCCESS,
} from '../helpers/snackBarMessages';

export const fetchStakeholders = businessId => dispatch => {
  dispatch({ type: FETCH_STAKEHOLDERS_START });
  axios
    .get(`/api/stakeholders/business/${businessId}`)
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_STAKEHOLDERS_SUCCESS, payload: resData.data });
    })
    .catch(() => {
      dispatch(addNotification(FETCH_STAKEHOLDER_MSG_ERROR, SNACKBAR_ERROR));
      dispatch({ type: FETCH_STAKEHOLDERS_FAILURE });
    });
};

export const createStakeholders = (
  stakeholder,
  businessId,
  userId
) => dispatch => {
  dispatch({ type: CREATE_STAKEHOLDERS_START });
  axios
    .post(`/api/stakeholders/`, { ...stakeholder })
    .then(res => {
      const resData = res.data;
      dispatch({ type: CREATE_STAKEHOLDERS_SUCCESS, payload: resData.data });
      dispatch(
        addNotification(CREATE_STAKEHOLDER_MSG_SUCCESS, SNACKBAR_SUCCESS)
      );
      dispatch(fetchSettingsResume(businessId, userId));
    })
    .catch(() => {
      dispatch({ type: CREATE_STAKEHOLDERS_FAILURE });
    });
};

export const editStakeholders = stakeholder => dispatch => {
  const tempStakeholder = { ...stakeholder };
  delete tempStakeholder.id;
  dispatch({ type: EDIT_STAKEHOLDERS_START });
  axios
    .put(`/api/stakeholders/${stakeholder.id}`, { ...tempStakeholder })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: EDIT_STAKEHOLDERS_SUCCESS,
        payload: { ...resData.data.stakeholder, id: stakeholder.id },
      });
      dispatch(addNotification(EDIT_STAKEHOLDER_MSG_SUCCESS, SNACKBAR_SUCCESS));
    })
    .catch(() => {
      dispatch({ type: EDIT_STAKEHOLDERS_FAILURE });
    });
};

export const deleteStakeholders = (
  stakeholderId,
  businessId,
  userId
) => dispatch => {
  dispatch({ type: DELETE_STAKEHOLDERS_START });
  axios
    .delete(`/api/stakeholders/${stakeholderId}`)
    .then(() => {
      dispatch({ type: DELETE_STAKEHOLDERS_SUCCESS, payload: stakeholderId });
      dispatch(
        addNotification(DELETE_STAKEHOLDER_MSG_SUCCESS, SNACKBAR_SUCCESS)
      );
      dispatch(fetchSettingsResume(businessId, userId));
    })
    .catch(() => {
      dispatch({ type: DELETE_STAKEHOLDERS_FAILURE });
    });
};

export const resetActions = () => dispatch => {
  dispatch({ type: RESET_STAKEHOLDERS_ACTIONS });
};

export const fetchLegalFile = type => dispatch => {
  dispatch({ type: DOWNLOADING_LEGAL_FILE_START, payload: { type } });
  axios
    .get(`api/legal/file?type=${type}`)
    .then(res => {
      const { file, name } = res.data.data;
      const blob = b64toBlob(file, 'application/pdf');
      dispatch({ type: DOWNLOADING_LEGAL_FILE_END, payload: { type } });
      return download(blob, name);
    })
    .catch(() => {
      const docLabel = type === POLICY ? POLICY_LABEL : TERMS_LABEL;
      dispatch({ type: DOWNLOADING_LEGAL_FILE_END, payload: { type } });
      dispatch(
        addNotification(`Error descargando ${docLabel}`, SNACKBAR_ERROR)
      );
    });
};
