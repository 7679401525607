import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import {
  createWalletRequest,
  fetchWalletRequest,
} from '../../actions/wallletAction';
import money from '../../assets/money.png';
import money2 from '../../assets/money2.png';
import money3 from '../../assets/money3.png';
import { LONG_DATE_FORMAT } from '../../helpers/Constants';
import GradientButton from '../form/GradientButton';
import Card from './Card';

const useStyles = makeStyles({
  availableLabel: {
    font: '13px/16px Muli',
    color: '#75B801',
    marginBottom: 10,
  },
  unavailableLabel: {
    font: '13px/16px Muli',
    color: '#95959A',
    marginBottom: 10,
  },
  rootCardExcedentes: {
    padding: '15px 10px',
    minHeight: 145,
    height: 145,
    width: 415,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:1440px)']: {
      width: 400,
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: '#F87348',
    marginLeft: 14,
    marginRight: 23,
  },
  title: {
    font: '16px/20px Muli',
    fontWeight: 700,
    color: '#575360',
    marginBottom: 6,
  },
  amount: {
    font: '18px/23px Muli',
    fontWeight: 700,
    color: '#575360',
  },
  body: {
    font: '12px/15px Muli',
    color: '#6C6776',
    marginBottom: 25,
  },
});

const WalletCard = props => {
  const { balance, loading, business } = props;

  const classes = useStyles();

  const walletRequests = useSelector(
    state => state.walletRequest.walletRequests
  );
  const dispatch = useDispatch();

  const hasWalletRequests = !!walletRequests.length;

  const finalBalance = Number.parseFloat(balance)
    ? parseFloat(balance).toFixed(0)
    : balance;

  let balanceImage = money2;
  if (finalBalance <= 0) {
    balanceImage = money;
  } else if (walletRequests.length) {
    balanceImage = money3;
  }

  let balanceText = 'Disponibles';
  let balanceTextClass = classes.availableLabel;
  let balanceButtonText = 'Solicitar devolución';
  if (finalBalance < 0) {
    balanceText = 'Deuda';
    balanceTextClass = classes.unavailableLabel;
  } else if (hasWalletRequests) {
    balanceText = `Solicitados el ${moment(walletRequests[0].createdAt).format(
      LONG_DATE_FORMAT
    )}`;
    balanceButtonText = 'Excedentes solicitados';
  }

  const requestSurplus = () => {
    dispatch(createWalletRequest(business.rut, 'EXCEDENTE'));
  };

  useEffect(() => {
    dispatch(fetchWalletRequest(business.rut, 'PENDING'));
  }, [dispatch, business]);

  return (
    <Card background={finalBalance > 0 ? '#E6F8C5' : '#E3E1E9'}>
      <Grid container direction="row" className={classes.rootCardExcedentes}>
        <Grid item className={classes.iconContainer}>
          <img src={balanceImage} width={80} className={classes.icon} alt="" />
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.title}>
            Saldo
          </Typography>
          {loading ? (
            <Skeleton variant="text" />
          ) : finalBalance !== 0 ? (
            <>
              <NumberFormat
                value={finalBalance}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString
                displayType="text"
                prefix="$"
                className={classes.amount}
              />
              <Typography variant="body1" className={balanceTextClass}>
                {balanceText}
              </Typography>
              <GradientButton
                fullWidth={false}
                onClick={requestSurplus}
                disabled={finalBalance <= 0 || hasWalletRequests}
              >
                {balanceButtonText}
              </GradientButton>
            </>
          ) : (
            <Typography variant="body1" className={classes.body}>
              Sin excedentes disponibles
            </Typography>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

WalletCard.defaultProps = {
  balance: 0,
};

WalletCard.propTypes = {
  balance: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  business: PropTypes.shape({ rut: PropTypes.string.isRequired }).isRequired,
};
export default WalletCard;
