import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Grid,
  InputAdornment,
  IconButton,
  createMuiTheme,
  Tooltip,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import CustomToolbarDatePicker from './CustomToolbarDatePicker';
import CustomPaginationMaterialTable from './CustomPaginationMaterialTable';
import AssistantTooltip from './AssistantTooltip';
import {
  DIRECT_FINANCING,
  EARLY_PAYMENT,
  LONG_DATE_FORMAT,
} from '../../helpers/Constants';

const outerTheme = createMuiTheme({
  typography: {
    fontFamily: ['Muli'],
  },
  palette: {
    primary: {
      main: '#f77240',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgb(245, 143, 105)',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&:hover': {
          backgroundColor: '#EDEAF0 !important',
        },
      },
    },
  },
});

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  deleteIconInactive: {
    color: '#A8A4B3',
  },
  deleteIconActive: {
    color: '#f77240',
  },
  underline: {
    minWidth: 130,
    fontSize: '14px',
    color: '#f77240',
    '& span': {
      color: '#f77240',
    },
    '&:before': {
      borderBottom: '2px solid #f77240',
      '& span': {
        color: '#f77240',
      },
    },
  },
  underlineDisabled: {
    minWidth: 130,
    fontSize: '14px',
    '&:before': {
      borderBottom: '2px solid #B2AEBD',
    },
  },
  underlineDisabledHeader: {
    minWidth: 130,
    fontWeight: 700,
    fontSize: '12px',
    '& svg': {
      color: '#A8A4B3',
    },
    '&:before': {
      borderBottom: '2px solid #B2AEBD',
    },
  },
  underlineHeader: {
    minWidth: 130,
    fontWeight: 700,
    fontSize: '12px',
    color: '#f77240',
    '& span': {
      color: '#f77240',
    },
    '&:before': {
      borderBottom: '2px solid #f77240',
      '& span': {
        color: '#f77240',
      },
    },
    '&:after': {},
  },
  inputRoot: {
    marginTop: 0,
  },
});

const styles = {
  cellStyle: {
    fontSize: '14px',
    color: '#6C6776',
  },
  nombre: {
    fontWeight: 'bold',
    color: '#575360',
    fontSize: 14,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  leftSpace: {
    borderLeft: '4px solid transparent',
  },
};

const SelectDateFacturasTable = props => {
  const classes = useStyles();
  const [bulkDate] = useState(null);
  const showAssistantStepper = useSelector(state => state.assistant.active);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [assistantTooltip, setAssistantTooltip] = useState(
    showAssistantStepper
  );
  const {
    invoices,
    flow,
    removeFromCart,
    handleDisabledDates,
    handleMonthChange,
    handleDateChange,
  } = props;

  const handleDelete = selectedInvoices => {
    removeFromCart(selectedInvoices, flow);
    setSelectedInvoices([]);
  };

  const handleDate = (date, selectedInvoices) => {
    handleDateChange(date, selectedInvoices);
    setSelectedInvoices([]);
    setAssistantTooltip(false);
  };

  const getcolumnsArray = () => {
    const columnsArray = [
      {
        width: 50,
        cellStyle: {
          alignContent: 'center',
        },
        title: (
          <Tooltip title="Selecciona las facturas que deseas eliminar">
            <IconButton
              className={
                selectedInvoices.length
                  ? classes.deleteIconActive
                  : classes.deleteIconInactive
              }
              onClick={() => handleDelete(selectedInvoices)}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
        ),
        render: rowData => {
          return (
            <IconButton
              className={classes.deleteIconInactive}
              onClick={() => handleDelete([rowData])}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          );
        },
      },
      { title: 'Folio', field: 'folio', width: 80 },
      {
        title: 'Nombre Pagador',
        field: 'rznSocRecep',
        width: 400,
        cellStyle: { ...styles.nombre },
      },
      {
        title: 'Rut Pagador',
        cellStyle: {
          ...styles.noWrap,
          ...styles.cellStyle,
        },
        render: rowData => `${rowData.rutReceptor}-${rowData.dvReceptor}`,
      },
      {
        title: 'Fecha de Emisión',
        field: 'fechaEmision',
        type: 'datetime',
        cellStyle: {
          ...styles.noWrap,
          ...styles.cellStyle,
        },
        minWidth: 130,
        render: rowData => {
          return moment
            .utc(new Date(rowData.fechaEmision))
            .locale('es')
            .format(LONG_DATE_FORMAT);
        },
      },
      {
        width: 170,
        minWidth: 170,
        title: (
          <ThemeProvider theme={outerTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <AssistantTooltip
                type="selectDate"
                open={assistantTooltip}
                element={
                  <Grid container justify="space-around">
                    <DatePicker
                      variant="inline"
                      placeholder="Fecha de Pago *"
                      format={LONG_DATE_FORMAT}
                      margin="normal"
                      value={bulkDate}
                      onChange={date => handleDate(date, selectedInvoices)}
                      className={classes.inputRoot}
                      InputProps={{
                        className: selectedInvoices.length
                          ? classes.underlineHeader
                          : classes.underlineDisabledHeader,
                        endAdornment: (
                          <InputAdornment position="end">
                            <InsertInvitationOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                      shouldDisableDate={date => handleDisabledDates(date)}
                      onMonthChange={date => handleMonthChange(date)}
                      minDateMessage="No se puede seleccionar fechas pasadas"
                      autoOk
                      disablePast
                      disableToolbar
                      onOpen={() => setAssistantTooltip(false)}
                    />
                  </Grid>
                }
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        ),
        render: rowData => {
          const {
            fechaVencimiento: dueDate,
            diasAtrasoPromedio: averageDaysLate,
          } = rowData;
          const message = (
            <span>
              Dado el historial de pago de tu proveedor
              <br />
              la fecha sugerida es de {averageDaysLate} días
            </span>
          );

          return (
            <ThemeProvider theme={outerTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <Grid container justify="space-around">
                  <DatePicker
                    variant="inline"
                    placeholder="Fecha de Pago"
                    format={LONG_DATE_FORMAT}
                    margin="normal"
                    value={dueDate || bulkDate}
                    onChange={date => handleDate(date, [rowData])}
                    className={classes.inputRoot}
                    InputProps={{
                      className:
                        dueDate || bulkDate
                          ? classes.underline
                          : classes.underlineDisabled,
                    }}
                    shouldDisableDate={date => handleDisabledDates(date)}
                    onMonthChange={date => handleMonthChange(date)}
                    minDateMessage="No se puede seleccionar fechas pasadas"
                    autoOk
                    disablePast
                    ToolbarComponent={() => (
                      <CustomToolbarDatePicker message={message} />
                    )}
                    onOpen={() => setAssistantTooltip(false)}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          );
        },
      },
      {
        title: 'Tasa',
        field: 'rate',
        type: 'numeric',
        render: rowData => (
          <NumberFormat
            value={rowData.rate}
            decimalSeparator=","
            thousandSeparator="."
            isNumericString
            displayType="text"
            suffix="%"
          />
        ),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
          minWidth: 110,
          ...styles.noWrap,
          ...styles.cellStyle,
        },
      },
      {
        title: 'Monto Total',
        field: 'mntFinal',
        type: 'numeric',
        render: rowData => (
          <NumberFormat
            value={rowData.mntFinal}
            decimalSeparator=","
            thousandSeparator="."
            isNumericString
            displayType="text"
            prefix="$"
          />
        ),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
          minWidth: 110,
          ...styles.noWrap,
          ...styles.cellStyle,
        },
      },
    ];
    if (flow === EARLY_PAYMENT) {
      columnsArray.splice(5, 0, {
        title: 'Porcentaje anticipo',
        field: 'advancePercentage',
        render: rowData => {
          const advancePercentage =
            rowData?.earlyPaymentFees?.advancePercentage;
          const advancePercentageTxt = advancePercentage
            ? `${advancePercentage}%`
            : '-';
          return advancePercentageTxt;
        },
      });
    }

    return columnsArray;
  };

  return (
    <MuiThemeProvider theme={outerTheme}>
      <MaterialTable
        style={{ width: '100%', boxShadow: 'none' }}
        title=""
        columns={getcolumnsArray()}
        data={invoices}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'facturas',
            labelRowsPerPage: 'Facturas por página',
          },
          toolbar: {
            nRowsSelected: count =>
              count > 1
                ? `${count} facturas seleccionadas`
                : '1 factura seleccionada',
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar',
          },
          body: {
            emptyDataSourceMessage: 'No hay facturas para mostrar',
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
        }}
        onSelectionChange={rows => setSelectedInvoices(rows)}
        options={{
          emptyRowsWhenPaging: false,
          selection: true,
          sorting: false,
          pageSize: 20,
          maxBodyHeight: 440,
          pageSizeOptions: [5, 10, 20, 50, 100],
          showSelectAllCheckbox: true,
          selectionProps: () => ({
            icon: <CircleUnchecked />,
            checkedIcon: <CircleCheckedFilled />,
          }),
          headerSelectionProps: {
            icon: <CircleUnchecked style={styles.leftSpace} />,
            checkedIcon: <CircleCheckedFilled style={styles.leftSpace} />,
            indeterminateIcon: <CircleUnchecked style={styles.leftSpace} />,
          },
          cellStyle: {
            ...styles.cellStyle,
          },
          rowStyle: rowData => {
            const {
              tableData: { checked = false },
            } = rowData;
            return {
              backgroundColor: checked ? '#F3F2F5' : '',
              borderLeft: checked
                ? '8px solid #f77240'
                : '8px solid transparent',
            };
          },
          headerStyle: {
            fontWeight: 'bold',
            fontSize: '12px',
            color: '#A8A4B3',
          },
        }}
        components={{
          // eslint-disable-next-line react/jsx-props-no-spreading
          Pagination: props => <CustomPaginationMaterialTable {...props} />,
        }}
      />
    </MuiThemeProvider>
  );
};
SelectDateFacturasTable.defaultProps = {
  flow: DIRECT_FINANCING,
};

SelectDateFacturasTable.propTypes = {
  invoices: PropTypes.arrayOf().isRequired,
  handleDateChange: PropTypes.func.isRequired,
  flow: PropTypes.string,
  removeFromCart: PropTypes.func.isRequired,
  handleDisabledDates: PropTypes.func.isRequired,
  handleMonthChange: PropTypes.func.isRequired,
};

export default SelectDateFacturasTable;
