import axios from 'axios';
import {
  RESET_PASSWORD_EMAIL_REQUEST_BEGIN,
  RESET_PASSWORD_EMAIL_REQUEST_SUCCESS,
  RESET_PASSWORD_EMAIL_REQUEST_FAILURE,
  NEW_PASSWORD_SUBMIT_BEGIN,
  NEW_PASSWORD_SUBMIT_SUCCESS,
  NEW_PASSWORD_SUBMIT_FAILURE,
} from './types';

export const resetPasswordEmailRequest = email => dispatch => {
  dispatch({
    type: RESET_PASSWORD_EMAIL_REQUEST_BEGIN,
  });
  axios
    .post('/api/user/resetpassword', email)
    .then(res => {
      dispatch({
        type: RESET_PASSWORD_EMAIL_REQUEST_SUCCESS,
      });
      return res;
    })
    .catch(err => {
      dispatch({
        type: RESET_PASSWORD_EMAIL_REQUEST_FAILURE,
        payload: err.response ? err.response.data : 'empty error',
      });
    });
};

export const setNewPassword = ({
  password,
  confirmPassword,
  token,
}) => dispatch => {
  dispatch({
    type: NEW_PASSWORD_SUBMIT_BEGIN,
  });
  axios
    .post(`/api/user/resetpassword/${token}`, {
      password,
      confirmPassword,
    })
    .then(res => {
      dispatch({
        type: NEW_PASSWORD_SUBMIT_SUCCESS,
      });
      return res;
    })
    .catch(err => {
      dispatch({
        type: NEW_PASSWORD_SUBMIT_FAILURE,
        payload: err.response ? err.response.data : 'empty error',
      });
    });
};
