import axios from 'axios';
import {
  FETCH_HOLIDAYS_START,
  FETCH_HOLIDAYS_SUCCESS,
  FETCH_HOLIDAYS_FAILURE,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const fetchHolidays = year => dispatch => {
  dispatch(dispatch({ type: FETCH_HOLIDAYS_START }));
  axios
    .get(`/api/holidays/${year}`)
    .then(res => {
      const resData = res.data;
      dispatch(dispatch({ type: FETCH_HOLIDAYS_SUCCESS, payload: resData }));
      return resData;
    })
    .catch(err => {
      dispatch(dispatch({ type: FETCH_HOLIDAYS_FAILURE, payload: err }));
    });
};
