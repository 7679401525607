import {
  REGISTER_BUSINESS_SERVICE_START,
  REGISTER_BUSINESS_SERVICE_SUCCESS,
  REGISTER_BUSINESS_SERVICE_FAILURE,
  REGISTER_BANK_ACCOUNT_START,
  REGISTER_BANK_ACCOUNT_SUCCESS,
  REGISTER_BANK_ACCOUNT_FAILURE,
  RESET_BANK_ACCOUNT_DIALOG,
  RESET_BUSINESS_SERVICE_DIALOG,
  DELETE_BANK_ACCOUNT_START,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
  RESET_BANK_ACCOUNT_DELETE_DIALOG,
  SET_DEFAULT_BANK_ACCOUNT_START,
  SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  SET_DEFAULT_BANK_ACCOUNT_FAILURE,
  RESET_DEFAULT_BANK_ACCOUNT_DIALOG,
  SETTINGS_RESUME_FAILURE,
  SETTINGS_RESUME_START,
  SETTINGS_RESUME_SUCCESS,
  SELECT_BANK_ACCOUNT,
} from '../actions/types';

const initialState = {
  registeringBusinessService: false,
  serviceRegistered: false,
  businessError: false,
  registeringBankAccount: false,
  bankAccountRegistered: false,
  bankAccountError: false,
  deletingBankAccount: false,
  bankAccountDeleted: false,
  selectedBankAccount: null,
  settingsResume: null,
  settingsResumeError: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REGISTER_BUSINESS_SERVICE_START:
      return {
        ...state,
        registeringBusinessService: true,
        serviceRegistered: false,
        businessError: false,
      };

    case REGISTER_BUSINESS_SERVICE_SUCCESS:
      return {
        ...state,
        registeringBusinessService: false,
        serviceRegistered: true,
      };

    case REGISTER_BUSINESS_SERVICE_FAILURE:
      return {
        ...state,
        registeringBusinessService: false,
        businessError: true,
      };

    case RESET_BUSINESS_SERVICE_DIALOG:
      return {
        ...state,
        registeringBusinessService: false,
        serviceRegistered: false,
        businessError: false,
      };

    case REGISTER_BANK_ACCOUNT_START:
      return {
        ...state,
        registeringBankAccount: true,
        bankAccountRegistered: false,
        bankAccountError: false,
      };

    case REGISTER_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        registeringBankAccount: false,
        bankAccountRegistered: true,
      };

    case REGISTER_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        registeringBankAccount: false,
        bankAccountError: true,
      };

    case RESET_BANK_ACCOUNT_DIALOG:
      return {
        ...state,
        registeringBankAccount: false,
        bankAccountRegistered: false,
        bankAccountError: false,
        selectedBankAccount: null,
      };

    case DELETE_BANK_ACCOUNT_START:
      return {
        ...state,
        deletingBankAccount: true,
        bankAccountDeleted: false,
        bankAccountError: false,
      };

    case DELETE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        deletingBankAccount: false,
        bankAccountDeleted: true,
      };

    case DELETE_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        deletingBankAccount: false,
        bankAccountError: true,
      };

    case RESET_BANK_ACCOUNT_DELETE_DIALOG:
      return {
        ...state,
        deletingBankAccount: false,
        bankAccountDeleted: false,
        bankAccountError: false,
        selectedBankAccount: null,
      };

    case SET_DEFAULT_BANK_ACCOUNT_START:
      return {
        ...state,
        settingDefaultBankAccount: true,
        bankAccountDefaultSet: false,
        bankAccountError: false,
      };

    case SET_DEFAULT_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        settingDefaultBankAccount: false,
        bankAccountDefaultSet: true,
      };

    case SET_DEFAULT_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        settingDefaultBankAccount: false,
        bankAccountError: true,
      };

    case RESET_DEFAULT_BANK_ACCOUNT_DIALOG:
      return {
        ...state,
        settingDefaultBankAccount: false,
        bankAccountDefaultSet: false,
        bankAccountError: false,
        selectedBankAccount: null,
      };

    case SELECT_BANK_ACCOUNT:
      return {
        ...state,
        selectedBankAccount: payload.bankAccount,
      };

    case SETTINGS_RESUME_START:
      return {
        ...state,
        settingsResume: null,
        settingsResumeError: false,
      };
    case SETTINGS_RESUME_SUCCESS:
      return {
        ...state,
        settingsResume: payload,
        settingsResumeError: false,
      };
    case SETTINGS_RESUME_FAILURE:
      return {
        ...state,
        settingsResume: null,
        settingsResumeError: true,
      };
    default:
      return state;
  }
};
