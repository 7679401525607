import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import XtrackerIcon from './icons/XtrackerIcon';
import Container from './elements/Container';
import SideBarNavigationButton from './elements/SideBarNavigationButton';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  leftContainer: {
    padding: 10,
    minHeight: 'calc(100vh - 56px)',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 7px #00000014',
  },
});

const HomeSideBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const handleButtonClick = newLocation => {
    history.push(newLocation);
  };
  return (
    <Hidden xsDown>
      <Container
        gridGap={10}
        sticky
        stickyTop={56}
        className={classes.leftContainer}
      >
        <SideBarNavigationButton
          icon={MonetizationOnOutlinedIcon}
          collapsed
          onClick={() => handleButtonClick('/')}
          active={location.pathname.includes('/finance')}
        >
          Financiamiento
        </SideBarNavigationButton>
        <SideBarNavigationButton
          icon={AssignmentIcon}
          collapsed
          onClick={() => handleButtonClick('/orders')}
          active={location.pathname.includes('/orders')}
        >
          Estado de Operaciones
        </SideBarNavigationButton>
        <SideBarNavigationButton
          icon={XtrackerIcon}
          collapsed
          onClick={() => handleButtonClick('/xtracker')}
          active={location.pathname.includes('/xtracker')}
        >
          Xtracker
        </SideBarNavigationButton>
      </Container>
    </Hidden>
  );
};

HomeSideBar.propTypes = {};

export default HomeSideBar;
