import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MaterialTableDTE from './elements/MaterialTableDTE';
import OperationResume from './elements/OperationResume';
import Container from './elements/Container';
import Section from './elements/Section';
import { setActiveStep, checkActiveDOM } from '../actions/assistantAction';
import { DIRECT_FINANCING, ASSISTANT_STEPS } from '../helpers/Constants';

import {
  fetchSiiUserTotalData,
  confirmFacturasSelection,
} from '../actions/sii';
import { trackSegment } from '../helpers/trackingSegment';

const useStyles = () => ({
  root: {
    flexGrow: 1,
  },
  mainContainer: {
    minHeight: 'fit-content',
  },
  tableInvoices: {
    margin: '0 -30px -15px -30px',
  },
});

const {
  STEP_OPERATION_SELECT_INVOICES,
  STEP_OPERATION_SELECT_DATES,
} = ASSISTANT_STEPS;

class HomeLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSiiDialog: false,
    };
  }

  componentDidMount() {
    // get user info from server
    const {
      handleStepChange,
      cleanCart,
      resetSelectedOrder,
      wasInFinance,
    } = this.props;
    // RESET SELECTED ORDER.THIS AVOIDS TRANSFER STATUS ORDERS RENDERING
    if (wasInFinance) {
      cleanCart();
      resetSelectedOrder();
    }

    handleStepChange(0);
  }

  componentDidUpdate(prevProps) {
    const { siiLoading, siiEnabled } = this.props;

    // if no userSii found, open sii credentials dialog
    if (prevProps.siiLoading !== siiLoading && !siiLoading && !siiEnabled) {
      this.handleOpenSiiDialog();
    }
  }

  // Track user clicking next step
  trackNextStepClicked = () => {
    const {
      selectedFacturas,
      totalFacturas: availableInvoiceAmount,
      totalSelectedFacturas: selectedInvoiceAmount,
      operationType,
    } = this.props;
    const invoiceQuantity = selectedFacturas.length;

    trackSegment('Order Selected', {
      operationType: operationType === DIRECT_FINANCING ? 'FD' : 'PP',
      invoiceQuantity,
      availableInvoiceAmount,
      selectedInvoiceAmount,
    });
  };

  handleNextButtonClick = () => {
    const {
      handleStepChange,
      confirmFacturasSelection,
      setActiveStep,
      showAssistantStepper,
      assistantActiveStep,
    } = this.props;

    this.trackNextStepClicked();

    confirmFacturasSelection();
    handleStepChange(1);
    if (
      showAssistantStepper &&
      assistantActiveStep === STEP_OPERATION_SELECT_INVOICES
    ) {
      setActiveStep(STEP_OPERATION_SELECT_DATES);
    }
  };

  handleOpenSiiDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      openSiiDialog: true,
    }));
  };

  handleCloseSiiDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      openSiiDialog: false,
    }));
  };

  render() {
    const {
      siiEnabled,
      handleLocationChange,
      totalFacturas,
      totalSelectedFacturas,
      selectedFacturas,
      classes,
      operationType,
      demo,
      assistantActiveStep,
      checkActiveDOM,
      assistantType,
    } = this.props;

    if (assistantActiveStep === STEP_OPERATION_SELECT_INVOICES) {
      checkActiveDOM(assistantActiveStep, document, assistantType);
    }

    return (
      <Grid
        id="main-table"
        className={classes.mainContainer}
        container
        justify="center"
      >
        <Container gridGap={30}>
          {!siiEnabled ? (
            <Container>
              <Typography variant="body2">
                Esta es una demo con información ficticia.
              </Typography>
            </Container>
          ) : null}
          <Container gridGap={30} gridTemplate="1fr 280px">
            <Container>
              <Section
                title="Selecciona una o más facturas que quieras financiar"
                bordered
              >
                <div className={classes.tableInvoices}>
                  <MaterialTableDTE
                    demo={demo}
                    handleLocationChange={handleLocationChange}
                    operation={operationType}
                  />
                </div>
              </Section>
            </Container>
            <Container sticky stickyTop={140}>
              <OperationResume
                title="Financiamiento"
                totalFacturas={totalFacturas || '-'}
                totalSelectedFacturas={totalSelectedFacturas || '-'}
                onClick={() => this.handleNextButtonClick()}
                disabled={!selectedFacturas?.length}
              />
            </Container>
          </Container>
        </Container>
      </Grid>
    );
  }
}

HomeLanding.propTypes = {
  operationType: PropTypes.string.isRequired,
  siiLoading: PropTypes.bool.isRequired,
  siiEnabled: PropTypes.bool.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    mainContainer: PropTypes.shape({}).isRequired,
    tableInvoices: PropTypes.shape({}).isRequired,
  }).isRequired,
  totalFacturas: PropTypes.number.isRequired,
  totalSelectedFacturas: PropTypes.number.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  selectedFacturas: PropTypes.arrayOf().isRequired,
  resetSelectedOrder: PropTypes.func.isRequired,
  cleanCart: PropTypes.func.isRequired,
  confirmFacturasSelection: PropTypes.func.isRequired,
  demo: PropTypes.bool.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  showAssistantStepper: PropTypes.bool.isRequired,
  wasInFinance: PropTypes.bool.isRequired,
  assistantActiveStep: PropTypes.number.isRequired,
  checkActiveDOM: PropTypes.func.isRequired,
  assistantType: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    siiEnabled: state.sii.siiEnabled,
    siiLoading: state.sii.siiLoading,
    totales: state.sii.siiUserTotalData,
    totalSelectedFacturas: state.sii.totalSelectedFacturas,
    facturas: state.sii.siiUserSelectedDTEs,
    loadStatus: state.ws.loadStatus,
    loadingSiiUserTotalData: state.sii.loadingSiiUserTotalData,
    loadingSiiUserSelectedDTEs: state.sii.loadingSiiUserSelectedDTEs,
    loadedSiiUserSelectedDTEs: state.sii.loadedSiiUserSelectedDTEs,
    selectedFacturas: state.sii.selectedFacturas,
    totalFacturas: state.sii.totalFacturas,
    cartStep: state.cart.step,
    cartSteps: state.cart.steps,
    showAssistantStepper: state.assistant.active,
    assistantActiveStep: state.assistant.activeStep,
    assistantType: state.assistant.type,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSiiUserTotalData: () => dispatch(fetchSiiUserTotalData()),
    confirmFacturasSelection: () => dispatch(confirmFacturasSelection()),
    resetSelectedOrder: () => dispatch({ type: 'RESET_SELECTED_ORDER' }),
    cleanCart: () => dispatch({ type: 'CLEAN_CART' }),
    setActiveStep: step => {
      dispatch(setActiveStep(step));
    },
    checkActiveDOM: (step, document, type) => {
      dispatch(checkActiveDOM(step, document, type));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(HomeLanding));
