import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Box, Grid, Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InfoField from './elements/InfoField';
import UserPasswordDialog from './elements/UserPasswordDialog';
import OrangeButton from './elements/OrangeButton';
import { updateUserInfo, updateBusinessInfo } from '../actions/authentication';
import CustomPanel from './elements/CustomPanel';
import { VALIDATION_TYPES, VALIDATION_MESSAGES } from '../helpers/Constants';

const useStyles = {
  container: {
    display: 'grid',
    gridGap: '32px',
  },
  title: {
    font: 'bold 20px Muli',
    color: '#575360',
  },
  subtitle: {
    font: 'normal 14px Muli',
    color: '#575360',
  },
  paddedContainer: {
    padding: 20,
  },
  cardTitle: {
    font: 'normal 16px Muli',
    color: '#75B801',
  },
  cardSubtitle: {
    color: '#A8A4B3',
  },
  userData: {
    fontWeight: 'bold',
    color: '#6C6776',
  },
  paper: {
    border: '1px solid #E3E1E8',
    borderRadius: '17px',
  },
};

class UserPersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserPasswordDialog: false,
      userInfo: {
        apellido: '',
        nombre: '',
        telefono: '',
      },
    };
  }

  componentDidUpdate = prevProps => {
    const { showUserPasswordDialog } = this.state;
    const { userUpdated, errors } = this.props;

    if (
      showUserPasswordDialog &&
      !prevProps.userUpdated &&
      userUpdated &&
      !errors
    ) {
      this.handleCloseUserPasswordDialog();
    }
  };

  componentDidMount = () => {
    const {
      user: { nombre, telefono, id },
    } = this.props;
    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        id,
        nombre,
        telefono,
      },
    }));
  };

  handleUpdateUserInfo = (name, value) => {
    const {
      user: { nombre, telefono },
      updateUserInfo,
    } = this.props;
    const newUser = {
      ...this.state.userInfo,
    };
    newUser[name] = value;
    this.setState(prevState => ({ ...prevState, userInfo: newUser }));
    const oldUserData = {
      name: nombre,
      phone: telefono,
    };
    updateUserInfo(newUser, oldUserData);
  };

  handlePasswordChange = userPasswordData => {
    const {
      user: { nombre, telefono, apellido },
      updateUserInfo,
    } = this.props;
    const oldUserData = {
      nombre,
      telefono,
      apellido,
    };
    const newUser = {
      ...oldUserData,
      ...userPasswordData,
    };
    updateUserInfo(newUser, oldUserData);
  };

  handleUpdateBusinessInfo = address => {
    const {
      updateBusinessInfo,
      user: { Businesses = [] },
    } = this.props;
    const [business = {}] = Businesses;
    const { id } = business;
    updateBusinessInfo({ id, address });
  };

  handleShowUserPasswordDialog = () => {
    this.props.resetPasswordDialog();
    this.setState(prevState => ({
      ...prevState,
      showUserPasswordDialog: true,
    }));
  };

  handleCloseUserPasswordDialog = () => {
    this.props.resetPasswordDialog();
    this.setState(prevState => ({
      ...prevState,
      showUserPasswordDialog: false,
    }));
  };

  render() {
    const { user, classes } = this.props;
    const { Businesses, nombre: name = '', telefono: phone = '', email } = user;
    const [business] = Businesses || [];
    const { name: razonSocial, rut, address } = business || {};
    const { showUserPasswordDialog } = this.state;
    const { REQUIRED } = VALIDATION_TYPES;
    const { REQUIRED_MSG } = VALIDATION_MESSAGES;
    return (
      <Fade in timeout={400}>
        <Box className={classes.container}>
          <UserPasswordDialog
            open={showUserPasswordDialog}
            handleSubmit={this.handlePasswordChange}
            handleCloseDialog={() => this.handleCloseUserPasswordDialog()}
          />

          <Typography variant="body" className={classes.title}>
            Perfil de usuario
          </Typography>

          <Typography variant="body" className={classes.subtitle}>
            Esta es tu información personal y de tu empresa.
          </Typography>

          <CustomPanel title="Información personal" variant="green-title">
            <Grid container direction="row" xs={12} spacing={2}>
              <Grid item xs={8}>
                <InfoField
                  label="Nombre y apellido"
                  data={name}
                  onAccept={value => this.handleUpdateUserInfo('nombre', value)}
                  editable
                  inputValidators={[REQUIRED]}
                  inputErrorMessages={[REQUIRED_MSG]}
                />
              </Grid>
              <Grid item xs={4}>
                <InfoField
                  label="Número de celular"
                  data={phone}
                  onAccept={value =>
                    this.handleUpdateUserInfo('telefono', value)
                  }
                  editable
                  inputValidators={[REQUIRED]}
                  inputErrorMessages={[REQUIRED_MSG]}
                />
              </Grid>
            </Grid>
          </CustomPanel>

          <CustomPanel title="Información de la empresa" variant="green-title">
            <Grid container direction="column" xs={12}>
              <Grid item xs={12}>
                <Grid container direction="row" xs={12} spacing={2}>
                  <Grid item xs={8}>
                    <InfoField label="Razón social" data={razonSocial} />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoField label="Rut empresa" data={rut} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" xs={12} spacing={2}>
                  <Grid item xs={8}>
                    <InfoField
                      label="Dirección"
                      data={address}
                      labelStyles={useStyles.label}
                      dataTextTransform="capitalize"
                      editable
                      onAccept={this.handleUpdateBusinessInfo}
                      inputValidators={[REQUIRED]}
                      inputErrorMessages={[REQUIRED_MSG]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoField
                      label="Email"
                      data={email}
                      labelStyles={useStyles.label}
                      dataStyles={useStyles.info}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CustomPanel>

          <CustomPanel
            variant="green-title"
            title="Contraseña de la cuenta"
            buttons={
              <OrangeButton
                onClick={this.handleShowUserPasswordDialog}
                text="Cambiar contraseña"
              />
            }
          />
        </Box>
      </Fade>
    );
  }
}

UserPersonalInfo.propTypes = {
  updateUserInfo: PropTypes.func.isRequired,
  updateBusinessInfo: PropTypes.func.isRequired,
  resetPasswordDialog: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    Businesses: PropTypes.arrayOf().isRequired,
    nombre: PropTypes.string.isRequired,
    telefono: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    apellido: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    container: PropTypes.objectOf(),
    title: PropTypes.objectOf(),
    subtitle: PropTypes.objectOf(),
  }).isRequired,
  userUpdated: PropTypes.bool.isRequired,
  errors: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    updatingUser: state.auth.updatingUser,
    userUpdated: state.auth.userUpdated,
    errors: state.auth.errors,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateUserInfo: (userInfo, oldUserData) =>
      dispatch(updateUserInfo(userInfo, oldUserData)),
    updateBusinessInfo: business => dispatch(updateBusinessInfo(business)),
    resetPasswordDialog: () => dispatch({ type: 'RESET_USER_PASSWORD_DIALOG' }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(UserPersonalInfo));
