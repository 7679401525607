import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Box, Fade } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftOutlined';
import UserProfileSidebar from './UserProfileSidebar';
import UserPersonalInfo from './UserPersonalInfo';
import UserSiiCredentials from './UserSiiCredentials';
import UserBankAccounts from './UserBankAccounts';
import UserPrevired from './UserPrevired';
import UserNotifications from './UserNotifications';
import { logoutUser } from '../actions/authentication';
import UserLegal from './UserLegal';
import AssistantStepper from './elements/AssistantStepper';
import { showAssistant } from '../actions/assistantAction';
import AssistantDialog from './elements/AssistantDialog';
import { ASSISTANT_STEPS } from '../helpers/Constants';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: '#f4f4f4',
    color: '#aca7b6',
    borderBottom: '1px solid #d9d9d9',
    '& .Mui-selected': {
      color: '#5d5966',
    },
  },
  mainContainer: {
    padding: '20px 40px',
    display: 'grid',
    gridGap: 20,
  },
  padded: {
    padding: 40,
  },
  leftContainer: {
    paddingTop: 50,
    minHeight: '100vh',
    backgroundColor: '#fff',
  },
  backButton: {
    fontWeight: 'normal',
    padding: 0,
    textTransform: 'capitalize',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: '#A8A4B3',
    fontFamily: 'Muli',
    '&:hover': {
      background: 'transparent',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
});

const HomeUserProfile = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showAssistantDialog, setShowAssistantDialog] = useState(false);
  const showAssistantStepper = useSelector(state => state.assistant.active);
  const assistantActiveStep = useSelector(state => state.assistant.activeStep);
  const settingsResume = useSelector(state => state.business.settingsResume);
  const user = useSelector(state => state.auth.user);
  const pfx = useSelector(state => state.sii.pfxCertificate);
  const siiEnabled = useSelector(state => state.sii.siiEnabled);
  const updateError = useSelector(state => state.auth.errors);
  const wasInUserProfile = history?.location?.state?.wasInUserProfile;

  useEffect(() => {
    const { STEP_PROFILE_FINISH } = ASSISTANT_STEPS;
    if (showAssistantStepper && assistantActiveStep === STEP_PROFILE_FINISH) {
      dispatch(showAssistant(false));
      setShowAssistantDialog(true);
    }
  }, [showAssistantStepper, assistantActiveStep, settingsResume, dispatch]);

  return (
    <Fade in timeout={!wasInUserProfile ? 400 : 0}>
      <Box className={classes.mainContainer} id="profile">
        <Grid container>
          <Button
            type="button"
            onClick={() => history.push('/')}
            className={classes.backButton}
          >
            <ChevronLeftIcon /> Volver
          </Button>
        </Grid>
        <Grid container justify="center">
          <AssistantStepper
            open={showAssistantStepper}
            handleClose={() => dispatch(showAssistant(false))}
          />
          <AssistantDialog
            step={2}
            open={showAssistantDialog}
            handleClose={() => setShowAssistantDialog(false)}
          />
          <Box width={1} maxWidth={1050} className={classes.test}>
            <Grid container direction="row" spacing={6}>
              <Grid item sm={3}>
                <UserProfileSidebar
                  bankAccountNotification={
                    user?.Businesses?.[0]?.BankAccounts?.length > 0
                  }
                  pfxCertificateNotification={!!pfx}
                  siiEnabledNotification={siiEnabled}
                  handleLogout={() => dispatch(logoutUser(history))}
                />
              </Grid>
              <Grid item sm={9}>
                <Switch>
                  <Route
                    exact
                    path="/user/profile/bank-accounts"
                    render={() => {
                      return <UserBankAccounts user={user} />;
                    }}
                  />

                  <Route
                    exact
                    path="/user/profile/legal"
                    render={() => {
                      return <UserLegal user={user} />;
                    }}
                  />

                  <Route
                    exact
                    path="/user/profile/sii"
                    component={UserSiiCredentials}
                  />

                  <Route
                    exact
                    path="/user/profile/previred"
                    component={UserPrevired}
                  />
                  <Route
                    exact
                    path="/user/profile/notifications"
                    render={() => {
                      return <UserNotifications user={user} />;
                    }}
                  />
                  <Route
                    render={() => {
                      return (
                        <UserPersonalInfo
                          user={user}
                          // classes={classes}
                          updateError={updateError}
                        />
                      );
                    }}
                  />
                </Switch>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Fade>
  );
};

export default HomeUserProfile;
