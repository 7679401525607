import { PURGE } from 'redux-persist';
import moment from 'moment';
import {
  REGISTER_SII_START,
  REGISTER_SII_SUCCESS,
  REGISTER_SII_FAILURE,
  FETCH_SIIUSER_TOTALS_DATA_START,
  FETCH_SIIUSER_TOTALS_DATA_SUCCESS,
  FETCH_SIIUSER_TOTALS_DATA_FAILURE,
  FETCH_SIIUSER_SELECTED_DTES_START,
  FETCH_SIIUSER_SELECTED_DTES_SUCCESS,
  FETCH_SIIUSER_SELECTED_DTES_FAILURE,
  CHECK_SII_SUCCESS,
  CHECK_SII_FAILURE,
  CHECK_SII_START,
  HANDLE_FACTURA_SELECTION_CHANGE,
  HANDLE_FACTURA_SELECTION_CONFIRM,
  HANDLE_FACTURA_DATE_CHANGE,
  CLEAN_CART,
  REGISTER_PFX_START,
  REGISTER_PFX_SUCCESS,
  REGISTER_PFX_FAILURE,
  HANDLE_CLOSE_PFX_DIALOG,
  CHECK_PFX_START,
  CHECK_PFX_SUCCESS,
  CHECK_PFX_FAILURE,
  REMOVE_FROM_CART,
  RESET_SII_DIALOG,
} from '../actions/types';
import { trackSegment } from '../helpers/trackingSegment';

const initialState = {
  credentialsSubmit: false,
  loadingSiiData: false,
  loadingSiiUserTotalData: false,
  loadingSiiUserSelectedDTEs: false,
  loadedSiiUserSelectedDTEs: false,
  siiEnabled: false,
  siiStatus: null,
  siiLoaded: false,
  siiLoading: false,
  siiUserTotalData: {},
  siiUserSelectedDTEs: [],
  siiUserAllDTEs: [],
  selectedFacturas: [],
  selectedFacturasByAlgorithm: [],
  inCartFacturas: [],
  totalSelectedFacturas: 0,
  totalInCartFacturas: 0,
  totalFacturas: 0,
  allFacturasWithDate: false,
  errors: '',
  uploadingPfx: false,
  pfxSuccess: false,
  pfxError: '',
  pfxCertificate: [],
  earlyPaymentCounter: '',
  directFinancingCounter: '',
};

const trackRemoveWithSegment = payload => {
  const { facturas, flow } = payload;
  // SEGMENT NO LEE ARREGLOS, HAY QUE NOTIFICAR UNA A UNA
  facturas.forEach(factura => {
    const {
      folio,
      rutReceptor: debtorRut,
      rznSocRecep: debtorName,
      mntTotal: totalAmount,
      fechaEmision,
      fechaVencimiento,
    } = factura;

    const trackPayload = {
      operationType: flow === 'EARLY_PAYMENT' ? 'PP' : 'FD',
      folio,
      issueDate: moment
        .utc(new Date(fechaEmision))
        .locale('es')
        .format('DD-MM-YYYY'),
      debtorRut,
      debtorName,
      totalAmount,
    };

    if (fechaVencimiento) {
      trackPayload.paymentDate = moment
        .utc(new Date(fechaVencimiento))
        .locale('es')
        .format('DD-MM-YYYY');
    }

    trackSegment('Invoice Removed', trackPayload);
  });
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REGISTER_SII_START:
      return { ...state, credentialsSubmit: true };

    case REGISTER_SII_SUCCESS:
      return { ...state, credentialsSubmit: false, loadingSiiData: true };

    case REGISTER_SII_FAILURE:
      return {
        ...state,
        credentialsSubmit: false,
        loadingSiiData: false,
        errors: payload.error.error,
      };

    case CHECK_SII_START:
      return {
        ...state,
        siiLoading: true,
      };
    case CHECK_SII_SUCCESS: {
      const newEnabled = payload.isSiiCredentialActive;
      return {
        ...state,
        siiEnabled: newEnabled,
        siiStatus: { ...payload },
        siiLoading: false,
        siiLoaded: true,
        loadingSiiData: false,
      };
    }
    case CHECK_SII_FAILURE:
      return {
        ...initialState,
        siiEnabled: false,
        siiLoading: false,
        siiLoaded: true,
        inCartFacturas: state.inCartFacturas,
        loadingSiiData: false,
      };

    case RESET_SII_DIALOG:
      return {
        ...state,
        siiEnabled: false,
        siiLoading: false,
        siiLoaded: false,
        loadingSiiData: false,
      };

    case FETCH_SIIUSER_TOTALS_DATA_START:
      return {
        ...state,
        loadingSiiUserTotalData: true,
      };
    case FETCH_SIIUSER_TOTALS_DATA_SUCCESS:
      return {
        ...state,
        loadingSiiUserTotalData: false,
        siiUserTotalData: {
          emitidas: { ...payload.data.emitidas },
          recibidas: { ...payload.data.recibidas },
        },
      };
    case FETCH_SIIUSER_TOTALS_DATA_FAILURE:
      return {
        ...state,
        loadingSiiUserTotalData: false,
      };
    case FETCH_SIIUSER_SELECTED_DTES_START:
      return {
        ...state,
        loadingSiiUserSelectedDTEs: true,
      };
    case FETCH_SIIUSER_SELECTED_DTES_SUCCESS: {
      let selectedFacturas;
      let selectedFacturasByAlgorithm;
      let facturas;
      let totalSelectedFacturas;
      let totalFacturas = 0;
      if (
        payload.data.facturasFiltradas &&
        payload.data.facturasFiltradas.length > 0
      ) {
        facturas = payload.data.facturasFiltradas;
        selectedFacturas = facturas.filter(
          factura => factura.tableData && factura.tableData.checked
        );
        selectedFacturasByAlgorithm = [...selectedFacturas];
        totalSelectedFacturas = selectedFacturas.reduce(
          (acc, val) => acc + Number(val.mntFinal),
          0
        );
        totalFacturas = facturas.reduce(
          (acc, val) => acc + Number(val.mntFinal),
          0
        );
      }
      return {
        ...state,
        loadingSiiUserSelectedDTEs: false,
        loadedSiiUserSelectedDTEs: true,
        siiUserAllDTEs: payload.data.facturas,
        siiUserSelectedDTEs: payload.data.facturasFiltradas,
        selectedFacturas,
        selectedFacturasByAlgorithm,
        totalSelectedFacturas,
        totalFacturas,
        earlyPaymentCounter: payload.data.earlyPaymentCounter,
        directFinancingCounter: payload.data.directFinancingCounter,
      };
    }
    case FETCH_SIIUSER_SELECTED_DTES_FAILURE:
      return {
        ...state,
        loadingSiiUserSelectedDTEs: false,
      };
    case HANDLE_FACTURA_SELECTION_CHANGE: {
      const totalSelectedFacturas = payload.reduce(
        (acc, val) => acc + Number(val.mntFinal),
        0
      );
      return {
        ...state,
        selectedFacturas: payload,
        totalSelectedFacturas,
      };
    }
    case HANDLE_FACTURA_SELECTION_CONFIRM: {
      const totalInCartFacturas = state.selectedFacturas.reduce(
        (acc, val) => acc + Number(val.mntFinal),
        0
      );
      return {
        ...state,
        totalInCartFacturas,
        inCartFacturas: state.selectedFacturas,
      };
    }
    case REMOVE_FROM_CART: {
      trackRemoveWithSegment(payload);
      // const inCartFacturas = remove(payload.key, 1, state.inCartFacturas);
      const inCartFacturas = state.inCartFacturas.filter(
        item =>
          !payload.facturas.some(
            fact =>
              fact.folio === item.folio && fact.rutReceptor === item.rutReceptor
          )
      );
      const totalInCartFacturas = inCartFacturas.reduce(
        (acc, val) => acc + Number(val.mntFinal),
        0
      );
      return { ...state, inCartFacturas, totalInCartFacturas };
    }
    case HANDLE_FACTURA_DATE_CHANGE: {
      let tmpInCartFacturas = [...state.inCartFacturas];
      const { newDate, facturas } = payload;
      if (newDate) {
        tmpInCartFacturas = state.inCartFacturas.map(stateFact => {
          if (
            facturas.length === 0 ||
            facturas.some(
              payloadFact =>
                payloadFact.folio === stateFact.folio &&
                payloadFact.rutReceptor === stateFact.rutReceptor
            )
          ) {
            return {
              ...stateFact,
              fechaVencimiento: moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
            };
          }
          return stateFact;
        });
      }

      const allFacturasWithDate = !tmpInCartFacturas.some(
        factura => !factura.fechaVencimiento
      );

      return {
        ...state,
        inCartFacturas: tmpInCartFacturas,
        allFacturasWithDate,
      };
    }
    case CLEAN_CART:
      return {
        ...state,
        selectedFacturas: initialState.selectedFacturas,
        totalSelectedFacturas: initialState.totalSelectedFacturas,
        inCartFacturas: initialState.inCartFacturas,
        totalInCartFacturas: initialState.totalInCartFacturas,
        siiUserTotalData: initialState.siiUserTotalData,
      };

    case REGISTER_PFX_START:
      return {
        ...state,
        pfxError: '',
        uploadingPfx: true,
      };
    case REGISTER_PFX_SUCCESS:
      return {
        ...state,
        uploadingPfx: false,
        pfxSuccess: true,
        pfxCertificate: payload.updatedBusiness.PfxCertificate,
      };
    case REGISTER_PFX_FAILURE: {
      const errorCode = payload.response.status;
      const pfxError =
        errorCode === 403
          ? payload.response.data.error
          : 'Ha ocurrido un error! Inténtalo nuevamente mas tarde.';

      return {
        ...state,
        uploadingPfx: false,
        pfxError,
      };
    }
    case HANDLE_CLOSE_PFX_DIALOG:
      return {
        ...state,
        uploadingPfx: false,
        pfxSuccess: false,
        pfxError: '',
      };

    case CHECK_PFX_START: {
      return {
        ...state,
        fetchingPfx: true,
      };
    }

    case CHECK_PFX_SUCCESS: {
      return {
        ...state,
        fetchingPfx: false,
        pfxCertificate: payload.pfxCertificate,
      };
    }

    case CHECK_PFX_FAILURE: {
      return {
        ...state,
        fetchingPfx: false,
        pfxError: payload.err,
      };
    }

    case PURGE: {
      return initialState;
    }

    default:
      return state;
  }
};
