// authentication.js

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {
  SET_CURRENT_USER,
  REGISTER_BEGIN,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_BAD_SII_CREDENTIALS,
  FETCH_USER_SERVICES_START,
  FETCH_USER_SERVICES_SUCCESS,
  FETCH_USER_SERVICES_FAILURE,
  REGISTER_USER_SERVICES_START,
  REGISTER_USER_SERVICES_SUCCESS,
  REGISTER_USER_SERVICES_FAILURE,
  UPDATE_USER_SERVICES_START,
  UPDATE_USER_SERVICES_SUCCESS,
  UPDATE_USER_SERVICES_FAILURE,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  REGISTER_INPUT_CHANGE,
  RESET_VERIFY_EMAIL,
} from './types';
import setAuthToken from '../helpers/setAuthToken';
import {
  identifyLoggedUser,
  identifyAndTrack,
  trackUpdatedWhatsapp,
  trackSegment,
} from '../helpers/trackingSegment';

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const registerBegin = () => ({
  type: REGISTER_BEGIN,
});

export const registerFailure = error => ({
  type: REGISTER_ERROR,
  payload: error,
});

export const registerBadSiiCredentials = error => ({
  type: REGISTER_BAD_SII_CREDENTIALS,
  payload: error,
});

export const registerUser = (user, utmTrackSegmentInfo) => dispatch => {
  dispatch(registerBegin());
  axios
    .post('/api/user/register', user)
    .then(res => {
      const { data: resData } = res.data;
      const { token } = resData;
      localStorage.setItem('jwtToken', token);
      setAuthToken(token);
      const { data = {} } = jwtDecode(token);
      const { utmCampaign, utmMedium, utmSource } = utmTrackSegmentInfo;
      identifyLoggedUser(data, utmCampaign, utmMedium, utmSource);
      trackSegment('Signup Complete', { authentication: 'email' });
      dispatch(setCurrentUser({ decoded: data, token }));
      dispatch({ type: REGISTER_SUCCESS });
    })
    .catch(err => {
      if (
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error === 'BAD_SII_CREDENTIALS'
      )
        dispatch(registerBadSiiCredentials());
      else dispatch(registerFailure(err));
    });
};

export const logoutUser = history => dispatch => {
  localStorage.removeItem('jwtToken');
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  history.push('/login');
};

export const fetchUserServices = userId => dispatch => {
  dispatch({ type: FETCH_USER_SERVICES_START });
  axios
    .get(`api/user/${userId}/services`)
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_USER_SERVICES_SUCCESS, payload: resData });
      return resData;
    })
    .catch(err => {
      dispatch({ type: FETCH_USER_SERVICES_FAILURE, payload: err });
    });
};

export const registerUserService = (
  userId,
  serviceData,
  businessId
) => dispatch => {
  const { name = '', data = {} } = serviceData;
  // businessId IS ONLY USED TO TRACK THE USER ACTION IN SEGMENT
  dispatch({ type: REGISTER_USER_SERVICES_START });
  axios
    .post(`api/user/${userId}/services`, {
      serviceData,
    })
    .then(res => {
      const resData = res.data;
      if (name === 'WHATSAPP') {
        const identPayload = {
          whatsapp: true,
          whatsappPhone: data,
        };
        const trackPayload = {
          whatsappPhone: data,
        };
        identifyAndTrack(
          businessId,
          identPayload,
          'Whatsapp Opt-In',
          trackPayload
        );
      }

      dispatch({ type: REGISTER_USER_SERVICES_SUCCESS, payload: resData });
      return resData;
    })
    .catch(err => {
      dispatch({ type: REGISTER_USER_SERVICES_FAILURE, payload: err });
    });
};

export const updateUserService = (
  userId,
  serviceData,
  businessId
) => dispatch => {
  // businessId IS ONLY USED TO TRACK THE USER ACTION IN SEGMENT
  dispatch({ type: UPDATE_USER_SERVICES_START });
  axios
    .put(`api/user/${userId}/services`, {
      serviceData,
    })
    .then(res => {
      const resData = res.data;
      const { name, data } = serviceData;
      if (name === 'WHATSAPP') {
        trackUpdatedWhatsapp(businessId, serviceData);
      } else if (name === 'EMAIL') {
        const trackMsg = 'Email edit';
        const payload = {
          email: data,
        };
        identifyAndTrack(businessId, payload, trackMsg, payload);
      }
      dispatch({ type: UPDATE_USER_SERVICES_SUCCESS, payload: resData });
      return resData;
    })
    .catch(err => {
      dispatch({ type: UPDATE_USER_SERVICES_FAILURE, payload: err });
    });
};
export const verifyEmail = email => dispatch => {
  dispatch({ type: VERIFY_EMAIL_START });
  axios
    .post(`/api/user/validation`, { email })
    .then(res => {
      const resData = res.data;
      dispatch({ type: VERIFY_EMAIL_SUCCESS, payload: resData });
    })
    .catch(error => {
      dispatch({ type: VERIFY_EMAIL_FAILURE, payload: error });
    });
};

export const handleInputChange = (field, value) => dispatch => {
  dispatch({ type: REGISTER_INPUT_CHANGE, field, value });
};

export const resetVerifyEmail = (field, value) => dispatch => {
  dispatch({ type: RESET_VERIFY_EMAIL, field, value });
};
