import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  FormControl,
  InputLabel,
  Typography,
  MenuItem,
  Select,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import SubmitLoadingGradientButton from '../form/SubmitLoadingGradientButton';
import verifyRut from '../../helpers/validation/rut';
import BaseDialog from './BaseDialog';
import {
  CUENTA_AHORRO,
  CUENTA_CORRIENTE,
  CUENTA_VISTA,
  BUTTON_LABEL,
} from '../../helpers/Constants';
import imgBanco from '../../assets/banco.svg';

const useStyles = {
  error: {
    color: '#FFF',
    background: '#D92F24',
    padding: 8,
    borderRadius: 5,
    textAlign: 'center',
    fontSize: '12px',
  },
  colorInput: {
    '& label.Mui-focused': {
      color: '#7300ffe0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#7300ffe0',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#7300ffe0',
      },
    },
  },
  menuItem: {
    font: 'normal 12px Muli',
  },
  selectOutlined: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '40px',
    },
    '& .MuiSelect-select:focus': {
      borderRadius: '40px',
      background: 'transparent',
    },
  },
  customInput: {
    '& .MuiFormHelperText-root': {
      font: 'bold 11px Muli',
    },
    background: 'transparent',
    font: 'normal 12px Muli',
    '& input': {
      font: 'normal 12px Muli',
    },
    '& label': {
      font: 'normal 12px Muli',
    },
    marginBottom: 12,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '40px',
      },
    },
  },
  selectLabel: {
    font: 'normal 12px Muli',
  },
  button: {
    marginTop: 30,
    marginBottom: 34,
  },
  bottomText: {
    font: 'normal normal normal 12px/15px Muli',
    letterSpacing: '0xp',
    color: '#575360',
    textAlign: 'center',
    top: '15px',
    left: '14px',
    width: '273px',
    height: '15px',
    margin: '20px 0px 40px 0px',
  },
};

class UserBankAccountDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bankId: '',
      accountType: '',
      accountNumber: '',
      accountEmail: '',
      alias: '',
    };
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule('isValidRut', value => {
      const validation = verifyRut(value);
      if (validation) return true;
      return false;
    });
  };

  handleInputChange = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  handleSubmit = () => {
    const {
      user: { Businesses: [business = {}] = [] },
    } = this.props;
    const { name: accountHolder, rut: accountRut } = business;
    const bankAccountData = { ...this.state, accountHolder, accountRut };
    this.props.handleSubmit(bankAccountData);
  };

  handleCloseDialog = () => {
    this.props.handleCloseDialog();
    this.setState({
      bankId: '',
      accountType: '',
      accountNumber: '',
      accountEmail: '',
      alias: '',
    });
  };

  render() {
    const {
      classes,
      open,
      banks,
      bankAccountError,
      user: { Businesses: [business = {}] = [] },
    } = this.props;
    const { name: accountHolder, rut: accountRut } = business;

    const {
      bankId,
      accountType,
      accountNumber,
      accountEmail,
      alias,
    } = this.state;

    return (
      <Grid container direction="column">
        <BaseDialog
          title="Agregar cuenta nueva"
          isOpen={open}
          handleClose={this.handleCloseDialog}
          topIcon={imgBanco}
          topIconWidth={172}
        >
          <Grid container direction="column" alignItems="center">
            <Grid item xs={10} style={{ minWidth: '83%' }}>
              <ValidatorForm
                onSubmit={this.handleSubmit}
                className={classes.colorInput}
              >
                <TextValidator
                  disabled
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Nombre o razón social"
                  type="text"
                  name="accountHolder"
                  value={accountHolder}
                  onChange={e => this.handleInputChange(e)}
                  validators={['required']}
                  className={classes.customInput}
                  errorMessages={['Campo requerido']}
                />
                <TextValidator
                  disabled
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Rut titular"
                  type="text"
                  name="accountRut"
                  value={accountRut}
                  onChange={e => this.handleInputChange(e)}
                  validators={['required', 'isValidRut']}
                  className={classes.customInput}
                  errorMessages={['Campo requerido', 'Rut inválido']}
                />
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  className={classes.selectOutlined}
                >
                  <InputLabel id="bankId" className={classes.selectLabel}>
                    Banco
                  </InputLabel>
                  <Select
                    labelId="bankId"
                    label="Banco"
                    name="bankId"
                    value={bankId}
                    fullWidth
                    onChange={this.handleInputChange}
                    className={classes.customInput}
                    validators={['required']}
                    errorMessages={['Campo requerido']}
                  >
                    {banks.map(bank => (
                      <MenuItem
                        value={bank.id}
                        key={bank.name}
                        classes={{ root: classes.menuItem }}
                      >
                        {bank.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  className={classes.selectOutlined}
                >
                  <InputLabel id="accountType" className={classes.selectLabel}>
                    Tipo de cuenta
                  </InputLabel>
                  <Select
                    labelId="accountType"
                    label="Tipo de cuenta"
                    name="accountType"
                    value={accountType}
                    fullWidth
                    onChange={e => this.handleInputChange(e)}
                    className={classes.customInput}
                    validators={['required']}
                    errorMessages={['Campo requerido']}
                  >
                    <MenuItem
                      value={CUENTA_CORRIENTE}
                      classes={{ root: classes.menuItem }}
                    >
                      Cuenta Corriente
                    </MenuItem>
                    <MenuItem
                      value={CUENTA_VISTA}
                      classes={{ root: classes.menuItem }}
                    >
                      Cuenta Vista
                    </MenuItem>
                    <MenuItem
                      value={CUENTA_AHORRO}
                      classes={{ root: classes.menuItem }}
                    >
                      Cuenta de Ahorro
                    </MenuItem>
                  </Select>
                </FormControl>

                <TextValidator
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Número de cuenta"
                  type="text"
                  name="accountNumber"
                  value={accountNumber}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  className={classes.customInput}
                  onChange={e => this.handleInputChange(e)}
                />
                <TextValidator
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email"
                  name="accountEmail"
                  value={accountEmail}
                  onChange={e => this.handleInputChange(e)}
                  validators={['required', 'isEmail']}
                  className={classes.customInput}
                  errorMessages={['Campo requerido', 'Email inválido']}
                />
                <TextValidator
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Alias (opcional)"
                  type="text"
                  name="alias"
                  value={alias}
                  className={classes.customInput}
                  onChange={e => this.handleInputChange(e)}
                />
                <Grid container="row">
                  <Typography className={classes.bottomText}>
                    Recuerda que las cuentas deben estar asociadas al RUT de la
                    empresa. Si necesitas crear una cuenta para terceros,
                    comunícate con nosotros a{' '}
                    <a href="mailto:hola@xepelin.com">hola@xepelin.com</a>
                  </Typography>
                </Grid>
                {bankAccountError && (
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.error}>
                      Ha ocurrido un error. Revisa los campos e intenta
                      nuevamente
                    </Typography>
                  </Grid>
                )}
                <div className={classes.button}>
                  <SubmitLoadingGradientButton label={BUTTON_LABEL.GUARDAR} />
                </div>
              </ValidatorForm>
            </Grid>
          </Grid>
        </BaseDialog>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    bankAccountError: state.business.bankAccountError,
  };
};

UserBankAccountDialog.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(Object).isRequired,
  banks: PropTypes.arrayOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
  bankAccountError: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(Object).isRequired,
};

export default connect(mapStateToProps)(
  withStyles(useStyles)(UserBankAccountDialog)
);
