import React from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import {
  Button,
  Input,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import CloudIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import StatusBadge from './StatusChip';
import Container from './Container';
import { ORDER_FACTURAS_STATUSES } from '../../helpers/Constants';

const useStyles = makeStyles({
  table: {
    '& .Mui-TableCell-head': {
      color: '#A8A4B3',
      fontSize: 12,
      fontWeight: 'bold',
    },
  },
  uploadButton: {
    color: '#A8A4B3',
    borderRadius: '40px',
    padding: 0,
    minWidth: '100%',
    '&:hover': {
      background: 'transparent',
    },
  },
  spinner: {
    color: '#7300FF',
  },
  success: {
    color: '#75B801',
  },
  deleteIcon: {
    color: '#F58F69',
  },
  green: {
    width: '100%',
    fontWeight: 'bold',
    color: '#75B801',
  },
  yellow: {
    width: '100%',
    fontWeight: 'bold',
    color: '#E29D02',
  },
  orange: {
    width: '100%',
    fontWeight: 'bold',
    color: '#E25F02',
  },
  rejected: {
    width: '100%',
    fontWeight: 'bold',
    color: '#D92F24',
  },
});

const FacturasTable = ({
  facturas,
  loading,
  uploadingPdf,
  deletingPdf,
  uploadingFacturaId,
  uploadFacturaPdf,
  deletePdf,
  tasaBase,
}) => {
  const classes = useStyles();
  const auth = useSelector(state => state.auth);

  const {
    user: { id: userId, Businesses = [] },
  } = auth;
  const [{ id: businessId } = {}] = Businesses;

  const handleFileInput = (facturaId, pdf) => {
    uploadFacturaPdf(facturaId, pdf, businessId, userId);
  };

  const handleDeletePdf = rowData => {
    const { id: orderFacturaId, OrderFacturaAttachments } = rowData;
    const { id: pdfId } = OrderFacturaAttachments[0];
    deletePdf(orderFacturaId, pdfId, businessId, userId);
  };

  const getComponentDays = factura => {
    const { green, yellow, orange, rejected } = classes;
    const { daysDebt } = factura;
    let classColor;
    let label = '-';
    if ((daysDebt || daysDebt === 0) && factura.status !== 'REJECTED') {
      label = `${daysDebt < 0 ? 0 : daysDebt} ${
        daysDebt !== 1 ? 'días' : 'día'
      }`;
      if (daysDebt <= -8) {
        classColor = green;
      } else if (daysDebt >= -8 && daysDebt < 0) {
        classColor = yellow;
      } else if (daysDebt > 0 && daysDebt <= 30) {
        classColor = orange;
      } else {
        classColor = rejected;
      }
    }
    return <span className={classColor}>{label}</span>;
  };

  const getColumns = () => {
    const showPdfColumn = facturas.length < 10;
    const { COMPLETE, REJECTED } = ORDER_FACTURAS_STATUSES;
    const columns = [
      { title: 'Folio', field: 'folio' },
      { title: 'Nombre pagador', field: 'rznSocRecep' },
      {
        title: 'RUT pagador',
        field: 'rutReceptor',
        render: rowData => `${rowData.rutReceptor}-${rowData.dvReceptor}`,
      },
      {
        title: 'Fecha de emisión',
        field: 'fechaEmision',
        render: rowData =>
          moment(rowData.fechaEmision)
            .utc()
            .locale('es')
            .format('DD MMMM YYYY'),
      },
      {
        title: 'Fecha de pago',
        field: 'fechaVencimiento',
        render: rowData =>
          moment(rowData.fechaVencimiento)
            .utc()
            .locale('es')
            .format('DD MMMM YYYY'),
      },
      {
        title: 'Días de mora',
        field: 'daysDebt',
        render: rowData => getComponentDays(rowData),
      },
      {
        title: 'Excedentes vigentes',
        field: 'amountRetention',
        render: rowData => {
          const { status, amountRetention } = rowData;

          const formattedAmount = Number.parseFloat(amountRetention)
            ? parseFloat(amountRetention).toFixed(0)
            : 0;
          const amount =
            status === COMPLETE || status === REJECTED ? '-' : formattedAmount;
          return (
            <NumberFormat
              value={amount || '-'}
              decimalSeparator=","
              thousandSeparator="."
              displayType="text"
              prefix="$"
              isNumericString
            />
          );
        },
      },
      {
        title: 'Monto total',
        field: 'mntTotal',
        render: rowData => (
          <NumberFormat
            value={rowData.mntTotal}
            decimalSeparator=","
            thousandSeparator="."
            displayType="text"
            prefix="$"
            isNumericString
          />
        ),
      },
      {
        title: 'Tasa',
        field: 'tasaBase',
        render: rowData => (
          <NumberFormat
            value={rowData.tasaBase || tasaBase || '-'}
            decimalSeparator=","
            thousandSeparator="."
            displayType="text"
            suffix="%"
            isNumericString
          />
        ),
      },
      {
        title: 'Estado',
        field: 'status',
        render: rowData => <StatusBadge status={rowData.statusGroup} />,
      },
    ];
    if (showPdfColumn) {
      columns.push({
        title: 'PDF',
        field: 'pdf',
        sorting: false,
        render: rowData =>
          showPdfColumn &&
          ((uploadingPdf || deletingPdf) &&
          rowData.id === uploadingFacturaId ? (
            <CircularProgress size={25} className={classes.spinner} />
          ) : rowData.OrderFacturaAttachments &&
            rowData.OrderFacturaAttachments.length ? (
            <Container
              gridTemplate="max-content max-content"
              gridGap={5}
              align="center"
            >
              <Tooltip title="PDF Subido">
                <CloudIcon className={classes.success} />
              </Tooltip>
              <Tooltip title="Eliminar PDF">
                <IconButton onClick={() => handleDeletePdf(rowData)}>
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              </Tooltip>
            </Container>
          ) : (
            <Button
              variant="container"
              size="small"
              component="label"
              className={classes.uploadButton}
            >
              <CloudIcon />
              <Input
                style={{ display: 'none' }}
                type="file"
                inputProps={{ accept: '.pdf' }}
                onChange={e => handleFileInput(rowData.id, e.target.files[0])}
              />
            </Button>
          )),
      });
    }

    return columns;
  };

  return (
    <MaterialTable
      className={classes.table}
      columns={getColumns()}
      isLoading={loading}
      data={facturas}
      title={null}
      options={{
        search: false,
        title: false,
        headerStyle: {
          color: '#A8A4B3',
          fontSize: 12,
          fontWeight: 'bold',
        },
      }}
      components={{
        Container: props => props.children,
        Toolbar: () => null,
      }}
    />
  );
};

FacturasTable.propTypes = {
  children: PropTypes.objectOf(Object).isRequired,
  facturas: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  uploadingPdf: PropTypes.bool.isRequired,
  deletingPdf: PropTypes.bool.isRequired,
  uploadingFacturaId: PropTypes.number.isRequired,
  uploadFacturaPdf: PropTypes.func.isRequired,
  deletePdf: PropTypes.func.isRequired,
  tasaBase: PropTypes.func.isRequired,
};

export default FacturasTable;
