import React, { useState, useEffect, memo } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import NumberFormat from 'react-number-format';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { disabledReceptors } from '../../helpers/disabledReceptors';
import CustomPaginationMaterialTable from './CustomPaginationMaterialTable';
import AssistantTooltip from './AssistantTooltip';
import { DIRECT_FINANCING, EARLY_PAYMENT } from '../../helpers/Constants';

const outerTheme = createMuiTheme({
  typography: {
    fontFamily: ['Muli'],
  },
  palette: {
    primary: {
      main: 'rgb(175, 171, 185)',
    },
    secondary: {
      main: 'rgb(245, 143, 105)',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&:hover': {
          backgroundColor: '#EDEAF0 !important',
        },
      },
    },
  },
});

const styles = {
  cellStyle: {
    fontSize: '14px',
    color: '#6C6776',
  },
  nombre: {
    fontWeight: 'bold',
    color: '#575360',
    fontSize: 14,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  leftSpace: {
    borderLeft: '4px solid transparent',
  },
};

const MaterialTableDTE = memo(props => {
  const { operation, demo } = props;
  const dispatch = useDispatch();

  const tableData = useSelector(state => state.sii.siiUserSelectedDTEs);
  const loading = useSelector(state => state.sii.loadingSiiUserSelectedDTEs);
  const siiEnabled = useSelector(state => state.sii.siiEnabled);
  const showAssistantStepper = useSelector(state => state.assistant.active);

  const [sortedTable, setSortedTable] = useState(false);
  const [assistantTooltip, setAssistantTooltip] = useState(
    showAssistantStepper
  );

  const demoData = [
    {
      folio: 456,
      rznSocRecep: 'Empresa 1',
      rutReceptor: '15.222.222',
      dvReceptor: 'K',
      fechaEmision: '9/6/2019',
      eightDays: 'SI',
      dehDescripcion: 'Emitido',
      mntFinal: '3880000',
      example: true,
      earlyPaymentFees: {
        rate: '1',
        advancePercentage: 100,
      },
    },
    {
      folio: 457,
      rznSocRecep: 'Empresa 2',
      rutReceptor: '15.222.222',
      dvReceptor: 'K',
      fechaEmision: '9/6/2019',
      eightDays: 'SI',
      dehDescripcion: 'Emitido',
      mntFinal: '3880000',
      example: true,
      earlyPaymentFees: {
        rate: '1',
        advancePercentage: 100,
      },
    },
    {
      folio: 458,
      rznSocRecep: 'Empresa 3',
      rutReceptor: '15.222.222',
      dvReceptor: 'K',
      fechaEmision: '9/6/2019',
      eightDays: 'SI',
      dehDescripcion: 'Emitido',
      mntFinal: '3880000',
      example: true,
      earlyPaymentFees: {
        rate: '1',
        advancePercentage: 100,
      },
    },
    {
      folio: 459,
      rznSocRecep: 'Empresa 4',
      rutReceptor: '15.222.222',
      dvReceptor: 'K',
      fechaEmision: '9/6/2019',
      eightDays: 'SI',
      dehDescripcion: 'Emitido',
      mntFinal: '3880000',
      example: true,
      earlyPaymentFees: {
        rate: '1',
        advancePercentage: 100,
      },
    },
  ];

  const handleFacturaSelectionChange = payload => {
    dispatch({ type: 'HANDLE_FACTURA_SELECTION_CHANGE', payload });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!sortedTable) {
      setSortedTable(true);
    }
  });

  const handleSelectionChange = rows => {
    handleFacturaSelectionChange(rows);
    setAssistantTooltip(false);
  };

  const tableDataInitialSort = () => {
    tableData.sort((x, y) => {
      if (x.tableData && y.tableData) {
        switch (x.tableData.checked) {
          case true:
            if (y.tableData.checked) {
              return y.mntTotal - x.mntTotal;
            }
            return -1;
          case undefined:
            if (y.tableData.checked) {
              return 1;
            }
            return y.mntTotal - x.mntTotal;
          default:
            return 0;
        }
      }
      return 0;
    });
  };

  const tableDataSelect = () => {
    if (demo) {
      if (operation === DIRECT_FINANCING) {
        return demoData;
      }
      return [];
    }

    if (tableData.length > 0 && !loading) {
      // Sorting by "checked"
      if (!sortedTable) {
        tableDataInitialSort();
      }
      return tableData;
    }

    if (siiEnabled) {
      return [];
    }

    return [];
  };

  const getColumns = () => {
    const columnsArray = [
      {
        title: (
          <AssistantTooltip
            type="selectInvoice"
            open={assistantTooltip}
            element={<div>Folio</div>}
          />
        ),
        field: 'folio',
        width: 100,
      },
      {
        title: 'Nombre Pagador',
        field: 'rznSocRecep',
        cellStyle: {
          minWidth: 400,
          ...styles.nombre,
        },
      },
      {
        title: 'Rut Pagador',
        field: 'rutReceptor',
        sorting: true,
        cellStyle: {
          ...styles.noWrap,
          ...styles.cellStyle,
        },
        render: rowData => `${rowData.rutReceptor}-${rowData.dvReceptor}`,
      },
      {
        title: 'Fecha de Emisión',
        field: 'fechaEmision',
        type: 'datetime',
        render: rowData => {
          return moment
            .utc(new Date(rowData.fechaEmision))
            .locale('es')
            .format('DD MMMM YYYY');
        },
      },
      { title: 'Estado', field: 'dehDescripcion' },
      {
        title: 'Monto Total',
        field: 'mntFinal',
        type: 'numeric',
        cellStyle: {
          ...styles.noWrap,
          ...styles.cellStyle,
        },
        render: rowData => (
          <NumberFormat
            value={rowData.mntFinal}
            decimalSeparator=","
            thousandSeparator="."
            isNumericString
            displayType="text"
            prefix="$"
          />
        ),
      },
    ];
    if (operation === EARLY_PAYMENT) {
      columnsArray.splice(6, 0, {
        title: 'Porcentaje anticipo',
        field: 'advancePercentage',
        render: rowData => {
          const { earlyPaymentFees, rutReceptor } = rowData;
          return earlyPaymentFees && !disabledReceptors.includes(rutReceptor)
            ? `${earlyPaymentFees.advancePercentage}%`
            : 'N/A';
        },
      });
    }
    return columnsArray;
  };

  return (
    <MuiThemeProvider theme={outerTheme}>
      <MaterialTable
        style={{ width: '100%', boxShadow: 'none' }}
        title=""
        columns={getColumns()}
        data={tableDataSelect()}
        isLoading={loading}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'facturas',
            labelRowsPerPage: 'Facturas por página',
          },
          toolbar: {
            nRowsSelected: count =>
              count > 1
                ? `${count} facturas seleccionadas`
                : '1 factura seleccionada',
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar',
          },
          header: {
            actions: 'Acciones',
          },
          body: {
            emptyDataSourceMessage: 'No hay facturas para mostrar',
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
        }}
        onSelectionChange={rows => handleSelectionChange(rows)}
        options={{
          emptyRowsWhenPaging: false,
          showFirstLastPageButtons: false,
          selection: true,
          sorting: true,
          pageSize: 20,
          maxBodyHeight: 440,
          pageSizeOptions: [5, 10, 20, 50, 100],
          showSelectAllCheckbox: true,
          selectionProps: rowData => ({
            disabled:
              disabledReceptors.includes(rowData.rutReceptor) &&
              operation === EARLY_PAYMENT,
            icon: <CircleUnchecked />,
            checkedIcon: <CircleCheckedFilled />,
          }),
          headerSelectionProps: {
            icon: <CircleUnchecked style={styles.leftSpace} />,
            checkedIcon: <CircleCheckedFilled style={styles.leftSpace} />,
            indeterminateIcon: <CircleUnchecked style={styles.leftSpace} />,
          },
          rowStyle: rowData => {
            const {
              tableData: { checked = false },
            } = rowData;
            return {
              backgroundColor: checked ? '#F3F2F5' : '',
              borderLeft: checked
                ? '8px solid #f77240'
                : '8px solid transparent',
            };
          },
          cellStyle: {
            ...styles.cellStyle,
          },
          headerStyle: {
            fontWeight: 'bold',
            fontSize: '12px',
            color: '#A8A4B3',
          },
        }}
        components={{
          // eslint-disable-next-line react/jsx-props-no-spreading
          Pagination: props => <CustomPaginationMaterialTable {...props} />,
        }}
      />
    </MuiThemeProvider>
  );
});

MaterialTableDTE.propTypes = {
  operation: PropTypes.string.isRequired,
  demo: PropTypes.bool.isRequired,
};

export default MaterialTableDTE;
