import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { DialogActions } from '@material-ui/core';
import { blurRoot, clearBlurRoot } from '../../helpers/utils';

const useStyles = makeStyles({
  root: {
    background: 'rgba(151, 176, 220, 0.3)',
    position: 'relative',
  },
  fixedWidthPaper: {
    borderRadius: 17,
    padding: '30px 10px',
    maxWidth: 580,
    display: 'grid',
    gridGap: 20,
    position: 'relative',
  },
  paper: {
    borderRadius: 17,
    padding: '30px 10px',
    display: 'grid',
    gridGap: 20,
    position: 'relative',
  },
  dialogTitle: {
    padding: '0 24px',
    '& h2': {
      font: 'Bold 20px/25px Muli',
    },
  },
  dialogContent: {
    padding: '0 24px',
  },
  dialogActions: {
    padding: '0 24px',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    width: 'min-content',
  },
});

const ModalDialog = props => {
  const {
    open,
    close,
    dialogTitle,
    dialogContent,
    dialogActions,
    size,
  } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
    const blurState = isOpen ? blurRoot() : clearBlurRoot();
    return blurState;
  }, [open, isOpen]);

  return (
    <Dialog
      fullWidth
      maxWidth={size || 'sm'}
      open={open}
      classes={{
        root: classes.root,
        paper: size ? classes.paper : classes.fixedWidthPaper,
      }}
    >
      {close && (
        <IconButton
          size="small"
          onClick={close}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      )}
      {dialogTitle && (
        <DialogTitle classes={{ root: classes.dialogTitle }} disableSpacing>
          {dialogTitle}
        </DialogTitle>
      )}
      {dialogContent && (
        <DialogContent classes={{ root: classes.dialogContent }}>
          {dialogContent}
        </DialogContent>
      )}
      {dialogActions && (
        <DialogActions classes={{ root: classes.dialogActions }}>
          {dialogActions}
        </DialogActions>
      )}
    </Dialog>
  );
};

ModalDialog.defaultProps = {
  close: null,
  dialogTitle: null,
  dialogContent: null,
  dialogActions: null,
  size: null,
};

ModalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func,
  dialogTitle: PropTypes.node,
  dialogContent: PropTypes.node,
  dialogActions: PropTypes.node,
  size: PropTypes.string,
};

export default ModalDialog;
