import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmAction from './ConfirmAction';

const useStyles = makeStyles({
  rootDialog: {
    borderRadius: 17,
    width: 428,
  },
  title: {
    font: '20px/25px Muli',
    fontWeight: 800,
    color: '#575360',
    marginTop: 50,
  },
  message: {
    marginTop: 10,
    color: '#575360',
    font: 'normal 14px/18px Muli',
  },
  actions: {
    justifyContent: 'center',
    paddingTop: 30,
    paddingBottom: 50,
  },
  closeButton: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
});
const ConfirmDialog = props => {
  const classes = useStyles();
  const {
    isOpen,
    handleClose,
    title,
    message,
    buttonOnAccept,
    buttonLabel,
  } = props;
  const [checked, setChecked] = useState(false);

  const closeDialogHandler = () => {
    handleClose();
    setChecked(false);
  };

  const confimHandler = () => {
    buttonOnAccept();
    setChecked(false);
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <Dialog
      open={isOpen}
      size="sm"
      maxWidth="sm"
      close={closeDialogHandler}
      classes={{ paper: classes.rootDialog }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeDialogHandler}
        >
          <CloseIcon />
        </IconButton>
        <Grid container align="center">
          <Grid item xs={12}>
            <Typography className={classes.title} variant="body1">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent align="center">
        <Typography className={classes.message} variant="body1">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <ConfirmAction
          buttonLabel={buttonLabel}
          buttonOnAccept={confimHandler}
          checked={checked}
          onChangeCheck={handleCheckboxChange}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonOnAccept: PropTypes.func.isRequired,
};

export default ConfirmDialog;
