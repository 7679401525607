import React from 'react';

import { Grid, Paper, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  content: {
    padding: 30,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: 20,
    minHeight: '75vh',
    borderRadius: 17,
    padding: '20px 0px',
    border: '1px solid #E7E5EC',
  },
});

const XtrackerPage = ({ businessId }) => {
  const classes = useStyles();

  const xtrackerUrl = `https://datastudio.google.com/report-framed/reporting/1PHpHoXxCyMJKxW2vpT7qXgAmwRM6L8Mq/page/eovHB?params=%7B%22df17%22:%22include%25EE%2580%25801%25EE%2580%2580EQ%25EE%2580%2580${businessId}%22%7D`;
  return (
    <Fade timeout={400} in>
      <div className={classes.content}>
        <Grid container>
          <Grid item xs={12}>
            <Paper spacing={3} elevation={0} className={classes.paper}>
              <iframe
                title="xtracker-iframe"
                allowFullScreen=""
                frameBorder="0"
                src={xtrackerUrl}
                width="100%"
                height="2000"
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

XtrackerPage.propTypes = {
  businessId: PropTypes.number.isRequired,
};

export default XtrackerPage;
