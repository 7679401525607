export default {
  // eslint-disable-next-line no-unused-vars
  0: () => {
    // migration clear out order state
    return {};
  },
  // eslint-disable-next-line no-unused-vars
  1: () => {
    // migration clear out order state
    return {};
  },
  2: () => {
    // migration clear out order state
    return {};
  },
  3: () => {
    // migration clear out order state
    return {};
  },
  4: () => {
    // migration clear out order state
    return {};
  },
  5: () => {
    // migration clear out order state
    return {};
  },
};
