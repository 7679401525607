import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import DollarIcon from '@material-ui/icons/AttachMoneyOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import RightArrowIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsNoneOutlined';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded';
import { setPendingTasks } from '../actions/assistantAction';
import SideBarNavigationButton from './elements/SideBarNavigationButton';
import FolderIcon from './icons/FolderIcon';
import { ASSISTANT_STEPS } from '../helpers/Constants';

const UserProfileSideBar = ({
  handleLogout,
  bankAccountNotification,
  pfxCertificateNotification,
  siiEnabledNotification,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const showAssistantStepper = useSelector(state => state.assistant.active);
  const assistantActiveStep = useSelector(state => state.assistant.activeStep);
  const {
    STEP_PROFILE_SII,
    STEP_PROFILE_LEGAL,
    STEP_PROFILE_BANK_ACCOUNT,
    STEP_PROFILE_PREVIRED,
  } = ASSISTANT_STEPS;

  const handleButtonClick = newLocation => {
    history.push(newLocation, { wasInUserProfile: true });
    if (showAssistantStepper) {
      dispatch(setPendingTasks(false));
    }
  };

  const useStyles = makeStyles({
    container: {
      display: 'grid',
      gridGap: 10,
      gridAutoRows: 'min-content',
    },
  });

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <SideBarNavigationButton
        onClick={() => handleButtonClick('/user/profile')}
        active={location.pathname === '/user/profile'}
        icon={PersonIcon}
        disabled={showAssistantStepper}
      >
        {' '}
        Perfil de Usuario
      </SideBarNavigationButton>

      <SideBarNavigationButton
        onClick={() => handleButtonClick('/user/profile/legal')}
        active={location.pathname === '/user/profile/legal'}
        icon={FolderIcon}
        indicator={
          showAssistantStepper && assistantActiveStep === STEP_PROFILE_LEGAL
        }
        disabled={
          location.pathname !== '/user/profile/legal' &&
          showAssistantStepper &&
          assistantActiveStep !== STEP_PROFILE_LEGAL
        }
      >
        {' '}
        Información Legal
      </SideBarNavigationButton>

      <SideBarNavigationButton
        onClick={() => handleButtonClick('/user/profile/bank-accounts')}
        active={location.pathname === '/user/profile/bank-accounts'}
        icon={DollarIcon}
        notification={!showAssistantStepper && !bankAccountNotification}
        indicator={
          showAssistantStepper &&
          assistantActiveStep === STEP_PROFILE_BANK_ACCOUNT
        }
        disabled={
          location.pathname !== '/user/profile/bank-accounts' &&
          showAssistantStepper &&
          assistantActiveStep !== STEP_PROFILE_BANK_ACCOUNT
        }
      >
        {' '}
        Cuentas Bancarias
      </SideBarNavigationButton>

      <SideBarNavigationButton
        onClick={() => handleButtonClick('/user/profile/sii')}
        active={location.pathname === '/user/profile/sii'}
        icon={DescriptionIcon}
        notification={
          !showAssistantStepper &&
          (!pfxCertificateNotification || !siiEnabledNotification)
        }
        disabled={
          location.pathname !== '/user/profile/sii' &&
          showAssistantStepper &&
          assistantActiveStep !== STEP_PROFILE_SII
        }
      >
        {' '}
        SII
      </SideBarNavigationButton>

      <SideBarNavigationButton
        onClick={() => handleButtonClick('/user/profile/previred')}
        active={location.pathname === '/user/profile/previred'}
        icon={RightArrowIcon}
        indicator={
          showAssistantStepper && assistantActiveStep === STEP_PROFILE_PREVIRED
        }
        disabled={
          location.pathname !== '/user/profile/previred' &&
          showAssistantStepper &&
          assistantActiveStep !== STEP_PROFILE_PREVIRED
        }
      >
        PreviRed
      </SideBarNavigationButton>
      <SideBarNavigationButton
        onClick={() => handleButtonClick('/user/profile/notifications')}
        active={location.pathname === '/user/profile/notifications'}
        icon={NotificationsIcon}
        disabled={showAssistantStepper}
      >
        {' '}
        Notificaciones
      </SideBarNavigationButton>

      <Divider light />

      <SideBarNavigationButton onClick={() => handleLogout()} icon={LogoutIcon}>
        {' '}
        Cerrar Sesión
      </SideBarNavigationButton>
    </Box>
  );
};

UserProfileSideBar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  bankAccountNotification: PropTypes.bool.isRequired,
  pfxCertificateNotification: PropTypes.bool.isRequired,
  siiEnabledNotification: PropTypes.bool.isRequired,
};

export default UserProfileSideBar;
