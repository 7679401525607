/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs, AppBar, Grid, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HomeCart from './HomeCart';
import Tab from './elements/Tab';
import {
  DIRECT_FINANCING,
  EARLY_PAYMENT,
  DIRECT_FINANCING_TAB,
  EARLY_PAYMENT_TAB,
  JOB_STATUSES,
  DEFAULT_STATE_CASES,
  DIRECT_FINANCING_LABEL,
  EARLY_PAYMENT_LABEL,
  ASSISTANT_TYPES,
  ASSISTANT_STEPS,
} from '../helpers/Constants';
import AssistantTooltip from './elements/AssistantTooltip';
import DefaultState from './elements/DefaultState';
import WelcomeDialog from './elements/WelcomeDialog';
import AssistantDialog from './elements/AssistantDialog';
import AssistantStepper from './elements/AssistantStepper';
import SiiDialog from './SiiDialog';
import HomeCards from './HomeCards';
import { checkSii, fetchSiiUserSelectedDTEs } from '../actions/sii';
import Loader from './elements/Loader';
import { setWasInFinance } from '../actions/uiStatesAction';
import {
  showAssistant,
  setActiveStep,
  checkActiveDOM,
} from '../actions/assistantAction';

const useStyles = makeStyles({
  hidden: {
    display: 'none',
  },
  content: {
    padding: 30,
  },
  root: {
    flexGrow: 1,
    marginTop: 60,
  },
  appBar: {
    paddingTop: 8,
    background: '#FFF',
    zIndex: 1,
    color: '#aca7b6',
    borderBottom: '1px solid #d9d9d9',
    '& .Mui-selected': {
      color: '#5d5966',
    },
  },
  cards: {
    background: '#FFF',
    position: 'sticky',
    top: 56,
    zIndex: 100,
  },
  appBarRoot: {
    position: 'sticky',
    top: 238,
    zIndex: 100,
  },
});

const HomeFunding = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const tour = useSelector(state => state.register.tour);
  const siiEnabled = useSelector(state => state.sii.siiEnabled);
  const siiStatus = useSelector(state => state.sii.siiStatus);
  const siiLoading = useSelector(state => state.sii.siiLoading);
  const wsLoadStatus = useSelector(state => state.ws.loadStatus);
  const token = useSelector(state => state.auth.token);
  const wasInFinance = useSelector(state => state.uiStates.wasInFinance);
  const showAssistantStepper = useSelector(state => state.assistant.active);
  const assistantActiveStep = useSelector(state => state.assistant.activeStep);
  const assistantActiveDom = useSelector(state => state.assistant.activeDOM);
  const assistantType = useSelector(state => state.assistant.type);
  const earlyPaymentCounter = useSelector(
    state => state.sii.earlyPaymentCounter
  );
  const directFinancingCounter = useSelector(
    state => state.sii.directFinancingCounter
  );
  const [activeTab, setActiveTab] = useState(
    location?.state?.oldActiveTab || DIRECT_FINANCING_TAB
  );
  const [showDefaultState, setShowDefaultState] = useState(false);
  const [showDemo, setShowDemo] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(true);
  const [showSiiDialog, setShowSiiDialog] = useState(false);
  const [showAssistantDialog, setShowAssistantDialog] = useState(false);
  const [operationType, setOperationType] = useState(DIRECT_FINANCING);
  const [showTour, setShowTour] = useState(false);

  const {
    hasSiiCredential = false,
    isSiiCredentialActive = false,
    siiJobState = '',
  } = siiStatus || {};

  const locationWasInFinance = location?.state?.wasInFinance;
  const { QUEUE, WORKING, FAILED, SUCCESS } = JOB_STATUSES;
  const { DF_DEFAULT, DF_FAILED, DF_LOADING, DF_UPDATE } = DEFAULT_STATE_CASES;
  const [dfSetting, setDFSetting] = useState(DF_DEFAULT);
  const {
    STEP_OPERATION_SII,
    STEP_OPERATION_LOADING,
    STEP_OPERATION_TYPE,
    STEP_OPERATION_SELECT_INVOICES,
  } = ASSISTANT_STEPS;

  useEffect(() => {
    if (
      [
        STEP_OPERATION_SII,
        STEP_OPERATION_LOADING,
        STEP_OPERATION_TYPE,
      ].includes(assistantActiveStep)
    ) {
      dispatch(checkActiveDOM(assistantActiveStep, document, assistantType));
    }
  }, [assistantActiveStep]);

  const updateDFSetting = () => {
    let settingDefaultState = DF_DEFAULT;
    if (hasSiiCredential) {
      if (isSiiCredentialActive) {
        if (
          siiJobState === QUEUE ||
          siiJobState === WORKING ||
          wsLoadStatus === WORKING
        ) {
          settingDefaultState = DF_LOADING;
        }
        if (siiJobState === FAILED || wsLoadStatus === FAILED) {
          settingDefaultState = DF_FAILED;
        }
      } else {
        settingDefaultState = DF_UPDATE;
      }
    }
    setDFSetting(settingDefaultState);
  };

  useEffect(() => {
    setLoadingCheck(siiLoading);
  }, [siiLoading]);

  useEffect(() => {
    const {
      hasSiiCredential = false,
      isSiiCredentialActive = false,
      siiJobState = '',
    } = siiStatus || {};
    if (!hasSiiCredential && !!wsLoadStatus) {
      dispatch(checkSii(token));
    }
    if (
      !hasSiiCredential ||
      !isSiiCredentialActive ||
      (siiJobState !== SUCCESS && wsLoadStatus !== SUCCESS)
    ) {
      setShowDefaultState(true);
    } else {
      setShowDefaultState(false);
      if (
        showAssistantStepper &&
        assistantActiveStep === STEP_OPERATION_LOADING
      ) {
        dispatch(setActiveStep(STEP_OPERATION_TYPE));
      }
    }
    updateDFSetting();
    setShowTour(tour);
  }, [siiStatus, wsLoadStatus, tour]);

  useEffect(() => {
    if (!siiEnabled && (wsLoadStatus === SUCCESS || wsLoadStatus === FAILED)) {
      dispatch(checkSii());
    } else if (
      siiEnabled &&
      showAssistantStepper &&
      assistantActiveStep === STEP_OPERATION_SII
    ) {
      dispatch(setActiveStep(STEP_OPERATION_LOADING));
    }
  }, [siiEnabled, wsLoadStatus]);

  useEffect(() => {
    dispatch(checkSii());
    if (!locationWasInFinance) {
      dispatch(setWasInFinance(false));
    }

    let newActiveTab = DIRECT_FINANCING_TAB;
    let newOperationType = DIRECT_FINANCING;
    const { pathname, state = {} } = location;
    const { showDemo = false } = state;
    if (pathname.includes('/finance/prontopago')) {
      newActiveTab = EARLY_PAYMENT_TAB;
      newOperationType = EARLY_PAYMENT;
    }
    setActiveTab(newActiveTab);
    setOperationType(newOperationType);
    setShowDemo(showDemo);

    dispatch(fetchSiiUserSelectedDTEs(newOperationType));
  }, [location]);

  useEffect(() => {
    if (showAssistantStepper && assistantActiveStep === STEP_OPERATION_SII) {
      setShowSiiDialog(true);
    }
  }, [showAssistantStepper, assistantActiveStep]);

  const handleTabChange = (event, value) => {
    const oldActiveTab = activeTab;
    let newPathname;
    switch (value) {
      case DIRECT_FINANCING_TAB:
        newPathname = '/finance/directo';
        setOperationType(DIRECT_FINANCING);
        break;

      case EARLY_PAYMENT_TAB:
        newPathname = '/finance/prontopago';
        setOperationType(EARLY_PAYMENT);
        break;

      default:
        newPathname = '/';
    }
    setActiveTab(value);
    history.push(newPathname, { showDemo, wasInFinance: true, oldActiveTab });
    dispatch(setWasInFinance(true));

    if (showAssistantStepper) {
      dispatch(setActiveStep(STEP_OPERATION_SELECT_INVOICES));
    }
  };

  const handleCloseTour = () => {
    setShowTour(!showTour);
    dispatch({
      type: 'RESET_TOUR',
    });
  };

  const handleAssistant = () => {
    setShowAssistantDialog(false);
    dispatch(showAssistant(true, ASSISTANT_TYPES.OPERATION));
    setShowSiiDialog(true);
  };

  const handleCloseAssistantDialog = () => {
    setShowAssistantDialog(false);
    setShowSiiDialog(true);
  };

  const handleCloseSiiDialog = () => {
    setShowSiiDialog(false);
  };

  const handleSkipSiiDialog = () => {
    if (showAssistantStepper) dispatch(showAssistant(false));
    setShowSiiDialog(false);
  };

  return (
    <>
      <SiiDialog
        open={showSiiDialog}
        handleCloseSiiDialog={handleCloseSiiDialog}
        handleSkipSiiDialog={handleSkipSiiDialog}
      />
      <WelcomeDialog open={showTour} handleClose={handleCloseTour} />
      <AssistantDialog
        step={0}
        open={showAssistantDialog}
        startAssistant={handleAssistant}
        handleClose={handleCloseAssistantDialog}
      />
      {assistantActiveDom && (
        <AssistantStepper
          open={showAssistantStepper}
          handleClose={() => dispatch(showAssistant(false))}
        />
      )}

      <Grid>
        <Grid item xs={12} className={classes.cards}>
          <HomeCards />
        </Grid>

        {loadingCheck ? (
          <Loader />
        ) : !showDemo && showDefaultState ? (
          <DefaultState
            showDemoOnClick={() => setShowDemo(!showDemo)}
            loadInvoicesOnClick={() => setShowAssistantDialog(true)}
            setting={dfSetting}
            handleCloseAssistant={() => dispatch(showAssistant(false))}
          />
        ) : (
          <Fade
            timeout={!wasInFinance ? 400 : 0}
            in={showDemo || !showDefaultState}
          >
            <div>
              <Grid className={classes.appBarRoot}>
                <AppBar
                  position="static"
                  className={classes.appBar}
                  elevation={0}
                >
                  <Grid id="grid-tabs" container direction="row">
                    <AssistantTooltip
                      type="selectTabs"
                      open={showAssistantStepper}
                      element={
                        <Grid item xs={4}>
                          <Tabs
                            className={classes.tabs}
                            value={activeTab}
                            onChange={handleTabChange}
                            aria-label="Navigation menu"
                            TabIndicatorProps={{
                              style: { backgroundColor: '#F09B70' },
                            }}
                          >
                            <Tab
                              value={DIRECT_FINANCING_TAB}
                              label={DIRECT_FINANCING_LABEL}
                              pillContent={
                                showDemo ? 4 : directFinancingCounter.toString()
                              }
                            />
                            <Tab
                              value={EARLY_PAYMENT_TAB}
                              label={EARLY_PAYMENT_LABEL}
                              pillContent={
                                showDemo ? 0 : earlyPaymentCounter.toString()
                              }
                            />
                          </Tabs>
                        </Grid>
                      }
                    />
                  </Grid>
                </AppBar>
              </Grid>
              <Grid className={classes.content}>
                <HomeCart operationType={operationType} demo={showDemo} />
              </Grid>
            </div>
          </Fade>
        )}
      </Grid>
    </>
  );
};

export default HomeFunding;
