import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Grid } from '@material-ui/core';
import Section from './Section';
import FeaturedAmount from './FeaturedAmount';
import GradientButton from '../form/GradientButton';
import Container from './Container';
import AssistantTooltip from './AssistantTooltip';

const OperationResume = ({
  title,
  totalFacturas,
  totalSelectedFacturas,
  onClick,
  disabled,
}) => {
  return (
    <Section
      title={title || ''}
      titleAlign="center"
      justify="center"
      gridGap={30}
      bordered
    >
      <FeaturedAmount
        title="Facturas disponibles"
        ammount={
          <NumberFormat
            value={totalFacturas}
            decimalSeparator=","
            thousandSeparator="."
            isNumericString
            displayType="text"
            prefix="$"
          />
        }
      />
      <FeaturedAmount
        title="Facturas seleccionadas"
        ammount={
          <NumberFormat
            value={totalSelectedFacturas}
            decimalSeparator=","
            thousandSeparator="."
            isNumericString
            displayType="text"
            prefix="$"
          />
        }
      />
      <Container justify="center">
        <AssistantTooltip
          open={!disabled}
          type="toTableDate"
          element={
            <Grid>
              <GradientButton onClick={onClick} disabled={disabled}>
                Siguiente →
              </GradientButton>
            </Grid>
          }
        />
      </Container>
    </Section>
  );
};

OperationResume.defaultProps = {
  title: PropTypes.string,
  totalFacturas: PropTypes.string,
  totalSelectedFacturas: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

OperationResume.propTypes = {
  title: PropTypes.string,
  totalFacturas: PropTypes.string,
  totalSelectedFacturas: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default OperationResume;
