import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Radio,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})(props => <Radio color="default" {...props} />);

const useStyles = makeStyles({
  accountHeader: {
    justifyContent: 'space-between',
  },
  alias: {
    font: 'bold 14px Muli',
    padding: '10px 0',
    color: '#575360',
  },
  info: {
    color: '#6C6776',
    font: 'normal 14px/23px Muli',
  },
});

const transformAccountTypes = accountType => {
  switch (accountType) {
    case 'CUENTA_CORRIENTE':
      return 'Cuenta corriente';
    case 'CUENTA_VISTA':
      return 'Cuenta vista';
    case 'CUENTA_AHORRO':
      return 'Cuenta de ahorro';

    default:
      return accountType;
  }
};

const BankAccountCard = ({
  bankAccount,
  handleShowDeleteDialog,
  handleShowDefaultAccountDialog,
}) => {
  const {
    bankName,
    accountType,
    accountNumber,
    accountHolder,
    alias,
    isDefault,
    accountRut,
    accountEmail,
  } = bankAccount;
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid item xs={1}>
        <Tooltip title="Cuenta predeterminada" placement="left">
          <div>
            <GreenRadio
              checked={isDefault}
              onChange={() => handleShowDefaultAccountDialog(bankAccount)}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={11}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.accountHeader}>
              <Grid item>
                <Typography variant="body1" className={classes.alias}>
                  {alias}
                </Typography>
              </Grid>
              {!isDefault && (
                <Grid item>
                  <IconButton
                    onClick={() => handleShowDeleteDialog(bankAccount)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.info}>
              {accountHolder}
            </Typography>
            <Typography variant="body1" className={classes.info}>
              {bankName} - {transformAccountTypes(accountType)}: {accountNumber}
            </Typography>
            <Typography variant="body1" className={classes.info}>
              {accountRut}
            </Typography>
            <Typography variant="body1" className={classes.info}>
              {accountEmail}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

BankAccountCard.propTypes = {
  bankAccount: PropTypes.shape({
    bankName: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    accountHolder: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
    isDefault: PropTypes.bool.isRequired,
    accountRut: PropTypes.string.isRequired,
    accountEmail: PropTypes.string.isRequired,
  }).isRequired,
  handleShowDefaultAccountDialog: PropTypes.func.isRequired,
  handleShowDeleteDialog: PropTypes.func.isRequired,
};

export default BankAccountCard;
