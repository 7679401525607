import axios from 'axios';
import {
  FETCH_BANKS_START,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAILURE,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const fetchBanks = () => dispatch => {
  dispatch({ type: FETCH_BANKS_START });
  axios
    .get('/api/banks/')
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_BANKS_SUCCESS, payload: resData });
      return resData;
    })
    .catch(() => {
      dispatch({ type: FETCH_BANKS_FAILURE });
    });
};
